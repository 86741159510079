@import url("https://fonts.googleapis.com/css2?family=Parisienne&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800&display=swap");
body {
  margin: 0 !important;
  padding: 0 !important;
  font-family: "Poppins", sans-serif;
  overflow-x: hidden;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html {
  scroll-behavior: smooth;
}

:root {
  --red: #ee2a32;
  --white: #fff;
  --green: #98e81d;
  --orange: #f87f07;
  --purple: #7e3661;
  --pink: #ea53c2;
  --blue: #def6fe;
  --blue2: #1b75b2;
  --deep-blue: #013266;
  --yellow: #e3db00;
  --black: #000;
  --links: #1dbce9;
  --gray: #c6c7c5;
  --gray1: #f8f7ed;
  --gray2: #7c7878;
  --gray3: #e1e1e1;
  --gray4: #a4a7a2;
  --main-font: 400;
  --main-font-Medium: 500;
  --main-font-SemiBold: 600;
  --main-font-Bold: 700;
  --main-font-ExtraBold: 800;
  --backgroundColor: rgba(246, 241, 209);
  --colorShadeA: rgb(106, 163, 137);
  --colorShadeB: rgb(121, 186, 156);
  --colorShadeC: rgb(150, 232, 195);
  --colorShadeD: rgb(187, 232, 211);
  --colorShadeE: rgb(205, 255, 232);
}

.BackendBody {
  width: 100%;
  min-height: 100vh;
  transition: ease all 0.3s;
  position: relative;
  padding: 0 25px 25px 85px;
  background-color: var(--gray1);
}

.BackendLeft {
  min-width: 60px;
  max-width: 60px;
  background-color: var(--blue2);
  color: #fff;
  transition: all 0.3s;
  position: relative;
  z-index: 11;
  box-shadow: 0 0 3px 0px rgb(0 0 0 / 40%);
  float: left;
  width: 100%;
  position: fixed;
  height: 100%;
  overflow: inherit;
}

.BackendOutlet {
  min-height: calc(100vh - 85px);
  display: flex;
  flex-direction: column;
}

.BackendHeader .MuiBox-root {
  position: fixed;
  margin-left: -25px;
}

:root {
  --red: #ee2a32;
  --white: #fff;
  --green: #98e81d;
  --orange: #f87f07;
  --purple: #7e3661;
  --pink: #ea53c2;
  --blue: #def6fe;
  --blue2: #1b75b2;
  --deep-blue: #013266;
  --yellow: #e3db00;
  --black: #000;
  --links: #1dbce9;
  --gray: #c6c7c5;
  --gray1: #f8f7ed;
  --gray2: #7c7878;
  --gray3: #e1e1e1;
  --gray4: #a4a7a2;
  --main-font: 400;
  --main-font-Medium: 500;
  --main-font-SemiBold: 600;
  --main-font-Bold: 700;
  --main-font-ExtraBold: 800;
  --backgroundColor: rgba(246, 241, 209);
  --colorShadeA: rgb(106, 163, 137);
  --colorShadeB: rgb(121, 186, 156);
  --colorShadeC: rgb(150, 232, 195);
  --colorShadeD: rgb(187, 232, 211);
  --colorShadeE: rgb(205, 255, 232);
}

.configure-role .MuiInputLabel-shrink {
  background: rgb(255, 255, 255);
}


.image_card_modal {
  overflow: hidden;
  overflow-y: auto;
}

.BackendBody {
  width: 100%;
  min-height: 100vh;
  transition: ease all 0.3s;
  position: relative;
  padding: 0 25px 25px 85px;
  background-color: var(--gray1);
}

.BackendLeft {
  min-width: 60px;
  max-width: 60px;

  background-color: var(--blue2);
  color: #fff;
  transition: all 0.3s;
  position: relative;
  z-index: 11;
  box-shadow: 0 0 3px 0px rgb(0 0 0 / 40%);
  float: left;
  width: 100%;
  position: fixed;
  height: 100%;
  overflow: inherit;
}
.folder_view_right .stageChipB{
  display: block;
    width: 100%;
}
.folder_view_right .stageChipB .MuiChip-root{
    margin: 10px 0;
    width: 100%;
    background: rgb(238,238,238);
    background: linear-gradient(180deg, rgba(238,238,238,1) 0%, rgb(185 180 170) 100%);
    border: 1px solid #e5e1e1;
    box-shadow: 2px 3px 5px #e1e0d5;
}
.folder_view_right .stageChipB .MuiSvgIcon-root{
      position: absolute;
      left: inherit;
      right: 0;
      top: 6px;
          background: #f67d24;
    color: #fff;
    border: 1px solid #b7530a;
}



.folder_view_right .Fv-tags li{}
.folder_view_right .MuiChip-root{
    width: 100%;
    background: rgb(238,238,238);
    background: linear-gradient(180deg, rgba(238,238,238,1) 0%, rgb(185 180 170) 100%);
    border: 1px solid #e5e1e1;
    box-shadow: 2px 3px 5px #e1e0d5;
}
.folder_view_right .Fv-tags .MuiSvgIcon-root{
  position: absolute;
    right: 0;
    top: -7px;
    left: inherit;
     background: #f67d24;
    color: #fff;
    border: 1px solid #b7530a;
}


.BackendOutlet {
  min-height: calc(100vh - 85px);
  display: flex;
  flex-direction: column;
}

.BackendHeader .MuiBox-root {
  position: fixed;
  margin-left: -25px;
}
.notes_details_custom_head {
  margin: 0 0 15px 0 !important;
  padding: 10px !important;
  background: #ebe2e2 !important;
}
.note_list_head .MuiTypography-h6 {
  font-size: 18px;
  color: #333;
  font-weight: 500;
  border-left: solid 5px var(--blue2);
}
/* /-------------------snackbar css----/ */

/* .tableProgressBar, .MuiLinearProgress-root {margin: 5px 0;} */

.MuiDialog-paperWidthSm .MuiLinearProgress-root {
  margin: 2px;
}

/* .MuiPopover-paper {
    margin-top: 0 !important;
} */

.MuiSnackbar-root {
  justify-content: center !important;
  left: 0 !important;
  right: 0 !important;
}

.MuiSnackbarContent-root {
  justify-content: center !important;
  background-color: #000 !important;
}

.MuiSnackbarContent-message {
  font-size: 20px;
  font-weight: 600;
  text-align: center;
}
/* /-----------css by subhashis------/ */
/* .landingpage6_common_width {width: 1800px;margin: 0 auto;} */
/*-----Backend left part -------------*/

.left_logo {
  background-color: #fff;
  display: block;
  padding: 5px;
  border-bottom: solid 1px #62a5d3;
}

.left_logo img {
  width: 100%;
}

.left_centerblock ul {
  margin: 0;
  padding: 0;
}

.left_centerblock ul li {
  list-style: none;
  display: block;
  border-bottom: solid 1px #62a5d3;
  position: relative;
}

.left_centerblock ul li button {
  list-style: none;
  display: block;
  border-bottom: solid 1px #62a5d3;
  position: relative;
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
}

.left_centerblock ul li button svg {
  width: 30px;
  height: 30px;
  color: var(--gray1);
}

.left_centerblock ul li a,
.left_centerblock ul li button {
  padding: 10px 0;
  display: block;
  position: relative;
  width: 100%;
}

.left_centerblock ul li img {
  width: 50%;
  display: block;
  margin: 0 auto;
  opacity: 0.4;
}

.left_centerblock ul li span {
  display: none;
  background-color: var(--orange);
  position: absolute;
  font-size: 12px;
  color: #fff;
  padding: 5px;
  border-radius: 5px;
  transform: translateY(-50%);
  top: 50%;
  left: 90%;
  z-index: 9;
}

.left_centerblock ul li span::after {
  content: "";
  clip-path: polygon(100% 0, 0 50%, 100% 100%);
  background-color: var(--orange);
  width: 10px;
  height: 10px;
  position: absolute;
  left: -10px;
  top: 50%;
  transform: translateY(-50%);
}

.left_centerblock ul li:hover span {
  display: block;
}

.left_centerblock ul li a.active,
.left_centerblock ul li button.active {
  background-color: var(--gray1);
  position: relative;
}

/* .left_centerblock  ul li a.active img{filter: inherit;} */

.left_centerblock ul li a.active,
.left_centerblock ul li button.active svg {
  color: var(--blue2);
}

.left_centerblock ul li a.active::after,
.left_centerblock ul li button.active::after {
  content: "";
  display: block;
  width: 5px;
  height: 100%;
  background-color: var(--gray1);
  position: absolute;
  right: -3px;
  top: 0;
}

.left_centerblock ul li ul {
  display: none;
  transition: all 0.8;
}

.left_centerblock ul li:hover ul {
  display: block;
  transition: all 0.8s;
}

.left_centerblock ul li ul li {
  border-top: solid 1px #62a5d3;
  border-bottom: none;
}

.left_centerblock ul li ul li button {
  padding: 10px 2px;
  font-size: 14px;
  color: #fff;
}

.left_centerblock ul li ul li button.active {
  background: var(--deep-blue);
}

.left_centerblock ul li ul li button:hover {
  background: var(--deep-blue);
}

.left_centerblock ul li ul li button.active::after {
  display: none;
}

.left_centerblock ul li.active ul {
  display: block;
}

.BackendLeft .left_centerblock ul li span svg {
  display: none;
}

.BackendLeft .MuiTouchRipple-root {
  display: none !important;
}

.BackendLeft .MuiButton-iconSizeMedium {
  display: none !important;
}

.left_centerblock ul li button {
  font-size: 12px !important;
  line-height: 14px;
  text-transform: capitalize;
  min-width: inherit;
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

/* span.MuiButton-endIcon {display: none !important;}
span.MuiButton-endIcon svg {display: none;} */

.ProgramBody {
  display: block;
  text-align: center;
}

.ProgramBody .chip_blockCon {
  font-size: 16px;
  background-color: #1976d2;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%),
    0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
  border-radius: 10px;
  padding: 8px 10px;
  font-size: 18px;
  display: block;
  color: #fff;
  margin: 5px 0;
  cursor: pointer;
}

.BackendLeft .left_menu_svg {
  display: block;
}

.BackendLeft .left_wrapper {
  position: relative;
}

.BackendLeft .left_menu_svg {
  display: block;
  position: absolute;
  right: 3px;
  top: 50%;
  transform: translateY(-50%);
}

.BoxMui_modalPage {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 10px solid #0569ad !important;
  background: #fff;
  padding: 20px !important;
  width: 400px;
}

.BoxMui_modalPage h2 {
  padding: 0px !important;
  color: #003166;
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 20px;
  text-align: center;
  line-height: 30px;
}

.BoxMui_modalPage .closeModalBtn {
  border-radius: 50% !important;
  background-color: #0569ad !important;
  position: absolute !important;
  top: -15px;
  right: -15px;
  padding: 0;
  width: 35px;
  height: 35px;
  color: var(--white) !important;
  min-width: inherit !important;
}

/* /-----------------large list modal css-------/ */

.BoxMui_modal {
  border: 10px solid #0569ad !important;
  background: #fff;
  padding: 20px !important;
}

.BoxMui_modal h2 {
  padding: 10px 80px !important;
  color: #003166;
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 20px;
  padding: 0;
  text-align: center;
  line-height: 30px;
}

.BoxMui_modal h3 {
  padding: 10px 34px !important;
  color: #003166;
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 20px;
  padding: 0;
  text-align: center;
  line-height: 30px;
}

.BoxMui_modal .closeModalBtn,
.modalCloseBtn,
.closeModalBtn {
  border-radius: 50% !important;
  background-color: #0569ad !important;
  position: absolute !important;
  top: -15px;
  right: -15px;
  padding: 0;
  width: 35px;
  height: 35px;
  color: var(--white) !important;
  min-width: inherit !important;
}

.BoxMui_modal .MuiButton-text:hover {
  background: #114170;
}

.BoxMui_modal .MuiButton-contained {
  margin: 0 5px;
  box-shadow: 2px 3px 8px #225a918f !important;
  background: #0569ad;
}

.BoxMui_modal .MuiButton-contained:hover {
  background-color: var(--deep-blue);
}

.lastLogin_Info .BoxMui_modal {
  width: 750px;
  padding: 0 !important;
}

/* .lastLogin_Info button {display: none;} */

.lastLogin_Info .userlisttable {
  padding: 10px;
}

.lastLogin_Info
  .rolelist_mainwrp
  .css-pjjt8i-MuiToolbar-root
  .MuiTypography-h6 {
  border-left: none;
}

.lastLogin_Info .MuiTypography-root {
  padding: 10px 0 !important;
  background: #0569ad;
  color: #fff !important;
  font-size: 30px !important;
  font-weight: 600 !important;
  margin-bottom: 10px;
}

.modal_title {
  padding: 0px 80px;
  text-align: center;
  font-size: 35px;
  font-weight: 600;
  color: #fff;
  background: #0569ad;
}

.property_container .property_label {
  font-weight: 600;
  width: 33%;
}

.property_container .property_value {
  width: 60%;
}
.property_container .property_value img {
  width: 100%;
  display: block;
}

.view_details .MuiTypography-root {
  padding: 20px 24px !important;
}

.view_details .MuiPaper-root {
  width: 600px;
}

.view_details .data_container {
  width: auto !important;
  box-shadow: none;
}

.view_details .data_block {
  margin: 0 !important;
  border-radius: 0 !important;
}

/* .view_details .MuiDialogContent-root {box-shadow: none;} */

/* /-----------------large list modal css-------/ */

/* /-----------------modal css-------/ */

.MuiDialogActions-root {
  justify-content: center !important;
  padding-bottom: 15px !important;
}

.MuiDialog-paper {
  border: 10px solid #0569ad;
}

.MuiDialogTitle-root {
  padding: 20px 80px !important;
}

.MuiDialogActions-root button {
  border-radius: 5px;
  box-shadow: 2px 3px 8px #225a918f !important;
  color: #fff !important;
  font-size: 20px;
  line-height: 26px;
  margin-right: 8px;
  padding: 8px 20px;
  text-transform: capitalize;
  background: var(--blue2);
}
.close_icon_btn:hover,
.close_icon_btn {
  background-color: var(--blue2) !important;
  color: #fff !important;
}
.adminformbody .MuiAutocomplete-inputRoot {
  padding: 0 !important;
}
.contractDetails .MuiDialog-paper p {
  color: #003166;
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 20px;
  padding: 0;
  text-align: center;
  line-height: 30px;
}

.MuiDialogActions-root button:hover {
  background-color: var(--deep-blue);
}

.notes_main_header {
  padding: 0 20px !important;
}

.notes_main_header p {
  margin-bottom: 0;
  font-size: 35px;
  color: var(--white);
  padding: 10px 5px;
  background: #003166;
  text-align: center;
}
.notes_details .notes_header p {
  text-align: center;
  font-size: 23px;
  line-height: 28px;
  padding: 0 10px;
}

.view_details .MuiDialogActions-root .close_btn {
  display: none;
}

.view_details .MuiDialogActions-root {
  padding: 0 !important;
}
.attachfile .link_input_container {
  width: 100%;
}

/* /-----------------modal css-------/ */

/* /-------------search modal css-----/ */

/* .searchBarHead {display: flex;justify-content: space-between;align-items: center;padding: 0 10px 0 50px;}

.searchForm {padding: 0 30px;display: flex;flex-wrap: wrap;align-items: center;}
.searchForm button {display: block;margin-left: auto;padding: 10px 30px;font-size: 20px;}
.searchForm .MuiFormControl-root {margin: 10px 0 !important;margin-top: 5px !important;width: 100%;}

.formGroup {flex: 1 0 45%;margin: 0 1%;}

@media only screen and (max-width: 899px) {
  .formGroup {flex: 1 0 80%;margin: 0;}
  .searchForm button {width: 250px;margin: 0 auto;}

} */

.searchBarHead svg {
  color: var(--white);
}

.searchBarHead .MuiIconButton-root {
  background: var(--white);
}

.searchBarHead .MuiIconButton-root svg {
  color: rgb(163, 159, 159);
}

.searchBarHead .MuiIconButton-root:hover {
  background: var(--white);
}

.PatientSearch .inputblock2line {
  flex: 1 0 48%;
  margin: 0px 0.3%;
  margin-bottom: 15px;
}

.PatientSearch .inputblock1line {
  width: 100%;
  margin: 0 0.3%;
}

.PatientSearch .inputblock4line {
  width: 30%;
  margin: 0 0.3%;
}

.PatientSearch .searchForm .MuiTextField-root {
  flex: 1 0 45%;
}

.PatientSearch .searchForm .MuiButton-contained {
  margin: 0 auto;
  font-size: 20px;
  margin-top: 10px;
}

.searchBarCloseIcon {
  position: absolute;
  right: 10px;
  top: 2px;
}

/* .searchForm .MuiFormControl-root {margin: 10px 0;} */

/* .searchForm .MuiFormControl-root {margin: 10px 0 !important;} */

.PatientSearch .inputblock1line .MuiFormControl-root {
  margin: 0 !important;
}

.PatientSearch .inputblock2line .MuiFormControl-root {
  margin: 0 !important;
}

.PatientSearch .inputblock2line .MuiFormLabel-root,
.PatientSearch .inputblock1line .MuiFormLabel-root {
  margin-bottom: 5px;
}

.PatientSearch .searchForm {
  margin-top: 20px;
  padding: 20px 40px;
  display: flex;
  flex-wrap: wrap;
}

.searchBarHeading {
  color: var(--white);
  text-align: center;
  font-size: 30px;
  padding: 0px 20px;
  background-color: #1976d2;
}

.notes_details .MuiDialog-paper {
  max-width: 90% !important;
  min-width: 600px !important;
  margin: 0 !important;
}

.notes_details .MuiDialog-paper .MuiToolbar-gutters {
  margin: 0;
  padding: 0;
  min-height: inherit;
  background: #f3f3f3;
}
.notes_details .MuiDialog-paper .css-19pregv-MuiPaper-root {
  box-shadow: none;
  margin: 0;
  padding: 0;
}
.notes_details .MuiDialog-paper .css-19pregv-MuiPaper-root .MuiTypography-h6 {
  margin: 0 0 15px 0;
  padding: 0 0 0 10px;
  font-size: 18px;
  color: #333;
  font-weight: 500;
  border-left: solid 5px var(--blue2);
}

.notes_details .notesTable .MuiTypography-h6 {
  margin: 0;
  padding: 0 0 0 10px;
  font-size: 18px;
  color: #333;
  font-weight: 500;
  border-left: solid 5px var(--blue2);
}

.notes_details .MuiDialog-paper .css-19pregv-MuiPaper-root th {
  border: solid 1px #ccc !important;
  padding: 4px 8px;
  text-align: left;
  font-size: 16px;
}
.notes_details .MuiDialog-paper .css-19pregv-MuiPaper-root td {
  border: solid 1px #ccc !important;
  padding: 4px 8px;
  text-align: left;
  font-size: 16px;
}
.notes_details .MuiDialog-paper .css-19pregv-MuiPaper-root th svg {
  color: #000 !important;
}

.notes_details {
  z-index: 100 !important;
}

.notes_details .notesTable th {
  border: solid 1px #ccc !important;
  padding: 4px 8px;
  text-align: left;
  font-size: 16px;
}
.notes_details .notesTable td {
  border: solid 1px #ccc !important;
  padding: 4px 8px;
  text-align: left;
  font-size: 16px;
}
.notes_details .notesTable th svg {
  color: #000 !important;
}

.css-1ty026z {
  padding: 0;
}

.notes_details .MuiDialog-paper .css-19pregv-MuiPaper-root td img {
  width: 180px;
  cursor: pointer;
}

.notes_details .MuiDialog-paper .css-19pregv-MuiPaper-root tr {
  position: relative;
}
.notes_details
  .MuiDialog-paper
  .css-19pregv-MuiPaper-root
  tr
  .MuiLinearProgress-root {
  position: absolute;
  left: 0;
  width: 100%;
}

.notes_details .MuiDialog-paper .css-19pregv-MuiPaper-root {
  margin: 0;
  min-height: inherit;
  padding: 0;
}

.textarea_add_note {
  width: 100%;
  height: 80px !important;
  padding: 10px;
  border-radius: 0px;
  outline: none;
  border: solid 1px #ccc !important;
  resize: none;
}
.notes_details .textarea_add_note {
  height: 100px !important;
}

.notes_details .MuiTypography-h6 {
  margin: 0 !important;
  padding: 0 10px !important;
}
.notes_details h2.MuiTypography-h6 {
  margin: 0 0 15px 0 !important;
  padding: 10px !important;
  background: #ebe2e2 !important;
}

.notes_details .stackPagination {
  margin: 15px 0;
}

.css-zw3mfo-MuiModal-root-MuiDialog-root {
  z-index: 1100 !important;
}
.notes_details .action_btn_container {
  display: block;
}
.notes_details .notes_btn_container {
  display: flex;
  justify-content: center;
  margin-top: 8px;
}

.css-1t1j96h-MuiPaper-root-MuiDialog-paper {
  max-width: inherit !important;
  max-width: 90% !important;
  overflow-y: inherit;
}
.css-126xj0f .MuiDialog-paperWidthSm {
  max-width: inherit !important;
  max-width: 90% !important;
  overflow-y: inherit;
}
.css-126xj0f {
  z-index: 100 !important;
}
.css-126xj0f .css-1rv1kxc {
  padding: 0 !important;
  background: #f3f3f3 !important;
  min-height: inherit !important;
}

.hmmodal .css-1rv1kxc .MuiTypography-h6 {
  margin: 0 0 15px 0;
  padding: 0 0 0 10px;
  font-size: 18px;
  color: #333;
  font-weight: 500;
  border-left: solid 5px var(--blue2);
}

.hmmodal_user_Box {
  margin: 0 !important;
  padding: 0 !important;
  min-width: 400px;

  height: 100%;
  overflow: hidden;
  overflow-y: auto;
}
.hmmodal_user_Box_wrapper {
  padding: 0;
}
.hmmodal_user_Box_wrapper h3 {
  margin-bottom: 0;
  font-size: 24px;
  color: var(--white);
  padding: 5px 15px;
  background: #003166;
  text-align: center;
  font-weight: normal;
}

.hmmodal_user_Box_wrapper h4 {
  margin-bottom: 0;
  font-size: 24px;
  color: var(--white);
  padding: 5px 15px;
  background: #003166;
  font-weight: normal;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.hmmodal_user_Box_wrapper h4 span {
  padding-right: 15px;
}
.hmmodal_user_Box_wrapper h4 .addCampaign_btn {
  display: block;
  font-size: 28px;
  width: 30px;
  height: 30px;
  background: #fff;
  color: #003166;
  border-radius: 100%;
  cursor: pointer;
  border: none;
}

.hmmodal_user_Box_wrapper .MuiFormControl-root {
  width: 100%;
}

.hmmodal_user_Box_wrapper form {
  padding: 15px;
}

.hmmodal_user_Box_wrapper .inputBlock1line {
  margin-bottom: 10px !important;
}

.MuiDialog-paperScrollPaper {
  overflow: inherit !important;
}
.hmmodal_user_Box_wrapper .inputBlock1line fieldset {
  border: none !important;
}
.hmmodal_user_Box_wrapper input {
  padding: 12px 14px;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  color: #333;
  border: solid 1px #ddd !important;
  outline: none !important;
  border-radius: 4px !important;
  opacity: inherit !important;
}
.hmmodal_user_Box_wrapper svg {
  width: 20px;
  height: 20px;
}

.hmmodal_user_Box_wrapper textarea {
  padding: 5px 14px;
  height: 60px !important;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  color: #333;
  border-radius: 0;
  resize: none;
  border: solid 1px #ddd !important;
  outline: none !important;
  border-radius: 4px !important;
}

.hmmodal_user_Box_wrapper .backend_addform button {
  border-radius: 5px;
  box-shadow: 2px 3px 8px #225a918f !important;
  color: #fff !important;
  font-size: 20px;
  min-width: inherit;
  line-height: 26px;
  margin-right: 8px;
  padding: 8px 15px;
  text-transform: capitalize;
  background: var(--blue2) !important;
  margin: 10px 5px 0 0 !important;
}

.hmmodalCloseBTN {
  border-radius: 50% !important;
  height: 30px;
  opacity: 1;
  padding: 5px !important;
  position: absolute !important;
  right: -14px;
  top: -17px;
  background-color: #0569ad !important;
  color: var(--white) !important;
  min-width: inherit !important;
  z-index: 9;
}

.folder_view_con .adminformbody .formInline .Formsubmit_button {
  margin-top: 0;
}

.NoRecordsDiv {
  display: block;
  width: 100%;
  text-align: center;
  background: #e9e7e7;
  padding: 10px;
  font-size: 18px;
  color: #ff0000 !important;
  margin: 0 5px;
}

.Noaccesspage {
  height: calc(100vh - 80px);
  width: 100vw;
  background: linear-gradient(
    45deg,
    #f8f7ed 12.5%,
    #fff 12.5%,
    #fff 37.5%,
    #f8f7ed 37.5%,
    #f8f7ed 62.5%,
    #fff 62.5%,
    #fff 87.5%,
    #f8f7ed 87.5%
  );
  background-size: 100px 100px;
  background-position: 50px 50px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.Noaccesspage_div {
  clip-path: polygon(100% 0, 100% 70%, 50% 100%, 0% 70%, 0 0);
  background-color: rgb(76 75 27 / 59%);
  border-radius: 10px 10px 0 0;
  width: 400px;
  max-width: 90%;
  padding: 15px;
  text-align: center;
}

.Noaccesspage_div h2 {
  margin: 0;
  padding: 0;
  font-size: 40px;
  line-height: 46px;
  color: #fff;
  position: relative;
}

.Noaccesspage_div h2::before {
  content: "";
  width: 120px;
  height: 5px;
  background: #fff;
  position: absolute;
  left: 50%;
  bottom: -10px;
  transform: translateX(-50%);
}

.Noaccesspage_div p {
  text-align: center;
  font-size: 22px;
  color: #fff;
  margin: 25px 0 0 0;
  padding: 0;
}
.Noaccesspage_div svg {
  color: #fff;
  width: 80px;
  height: 80px;
  margin-top: 10px;
}

.landingmodal {
  width: 100vw;
  height: 100vh;
  display: table-cell;
  align-items: center;
  vertical-align: middle;
}

.notes_details .MuiDialog-paper .MuiToolbar-gutters {
  margin: 0;
  padding: 0;
  min-height: inherit;
  background: #f3f3f3;
}
.notes_details .MuiDialog-paper .css-19pregv-MuiPaper-root {
  box-shadow: none;
  margin: 0;
  padding: 0;
}
.notes_details .MuiDialog-paper .css-19pregv-MuiPaper-root .MuiTypography-h6 {
  margin: 0 0 15px 0;
  padding: 0 0 0 10px;
  font-size: 18px;
  color: #333;
  font-weight: 500;
  border-left: solid 5px var(--blue2);
}

.notes_details .notesTable .MuiTypography-h6 {
  margin: 0;
  padding: 0 0 0 10px;
  font-size: 18px;
  color: #333;
  font-weight: 500;
  border-left: solid 5px var(--blue2);
}

.notes_details .MuiDialog-paper .css-19pregv-MuiPaper-root th {
  border: solid 1px #ccc !important;
  padding: 4px 8px;
  text-align: left;
  font-size: 16px;
}
.notes_details .MuiDialog-paper .css-19pregv-MuiPaper-root td {
  border: solid 1px #ccc !important;
  padding: 4px 8px;
  text-align: left;
  font-size: 16px;
}
.notes_details .MuiDialog-paper .css-19pregv-MuiPaper-root th svg {
  color: #000 !important;
}

.notes_details {
  z-index: 100 !important;
}

.notes_details .notesTable th {
  border: solid 1px #ccc !important;
  padding: 4px 8px;
  text-align: left;
  font-size: 16px;
}
.notes_details .notesTable td {
  border: solid 1px #ccc !important;
  padding: 4px 8px;
  text-align: left;
  font-size: 16px;
}
.notes_details .notesTable th svg {
  color: #000 !important;
}

.css-1ty026z {
  padding: 0;
}

.notes_details .MuiDialog-paper .css-19pregv-MuiPaper-root td img {
  width: 180px;
  cursor: pointer;
}

.notes_details .MuiDialog-paper .css-19pregv-MuiPaper-root tr {
  position: relative;
}
.notes_details
  .MuiDialog-paper
  .css-19pregv-MuiPaper-root
  tr
  .MuiLinearProgress-root {
  position: absolute;
  left: 0;
  width: 100%;
}

.notes_details .MuiDialog-paper .css-19pregv-MuiPaper-root {
  margin: 0;
  min-height: inherit;
  padding: 0;
}

.textarea_add_note {
  width: 100%;
  height: 50px !important;
  padding: 10px;
  border-radius: 0px;
  outline: none;
  border: solid 1px #ccc !important;
  resize: none;
}

.notes_details .MuiTypography-h6 {
  margin: 0 !important;
  padding: 0 10px !important;
}
.notes_details h2.MuiTypography-h6 {
  margin: 0 0 15px 0 !important;
  padding: 10px !important;
  background: #ebe2e2 !important;
}

.notes_details .stackPagination {
  margin: 15px 0;
}

.css-zw3mfo-MuiModal-root-MuiDialog-root {
  z-index: 1100 !important;
}
.notes_details .action_btn_container {
  display: block;
}
.notes_details .notes_btn_container {
  display: flex;
  justify-content: center;
  margin-top: 8px;
}

.css-1t1j96h-MuiPaper-root-MuiDialog-paper {
  max-width: inherit !important;
  max-width: 90% !important;
  overflow-y: inherit;
}
.hmmodal_user_Box {
  margin: 0 !important;
  padding: 0 !important;
  min-width: 400px;

  height: 100%;
  overflow: hidden;
  overflow-y: auto;
}
.hmmodal_user_Box_wrapper {
  padding: 0;
}
.hmmodal_user_Box_wrapper h3 {
  margin-bottom: 0;
  font-size: 24px;
  color: var(--white);
  padding: 5px 15px;
  background: #003166;
  text-align: center;
  font-weight: normal;
}

.hmmodal_user_Box_wrapper h4 {
  margin-bottom: 0;
  font-size: 24px;
  color: var(--white);
  padding: 5px 15px;
  background: #003166;
  font-weight: normal;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.hmmodal_user_Box_wrapper h4 span {
  padding-right: 15px;
}
.hmmodal_user_Box_wrapper h4 .addCampaign_btn {
  display: block;
  font-size: 28px;
  width: 30px;
  height: 30px;
  background: #fff;
  color: #003166;
  border-radius: 100%;
  cursor: pointer;
  border: none;
}

.hmmodal_user_Box_wrapper .MuiFormControl-root {
  width: 100%;
}

.hmmodal_user_Box_wrapper form {
  padding: 15px;
}

.hmmodal_user_Box_wrapper .inputBlock1line {
  margin-bottom: 10px !important;
}

.MuiDialog-paperScrollPaper {
  overflow: inherit !important;
}
.hmmodal_user_Box_wrapper .inputBlock1line fieldset {
  border: none !important;
}
.hmmodal_user_Box_wrapper input {
  padding: 12px 14px;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  color: #333;
  border: solid 1px #ddd !important;
  outline: none !important;
  border-radius: 4px !important;
  opacity: inherit !important;
}
.hmmodal_user_Box_wrapper svg {
  width: 20px;
  height: 20px;
}

.hmmodal_user_Box_wrapper textarea {
  padding: 5px 14px;
  height: 60px !important;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  color: #333;
  border-radius: 0;
  resize: none;
  border: solid 1px #ddd !important;
  outline: none !important;
  border-radius: 4px !important;
}

.hmmodal_user_Box_wrapper .backend_addform button {
  border-radius: 5px;
  box-shadow: 2px 3px 8px #225a918f !important;
  color: #fff !important;
  font-size: 20px;
  min-width: inherit;
  line-height: 26px;
  margin-right: 8px;
  padding: 8px 15px;
  text-transform: capitalize;
  background: var(--blue2) !important;
  margin: 10px 5px 0 0 !important;
}

.hmmodalCloseBTN {
  border-radius: 50% !important;
  height: 30px;
  opacity: 1;
  padding: 5px !important;
  position: absolute !important;
  right: -14px;
  top: -17px;
  background-color: #0569ad !important;
  color: var(--white) !important;
  min-width: inherit !important;
  z-index: 9;
}

.folder_view_con .adminformbody .formInline .Formsubmit_button {
  margin-top: 0;
}

.NoRecordsDiv {
  display: block;
  width: 100%;
  text-align: center;
  background: #e9e7e7;
  padding: 10px;
  font-size: 18px;
  color: #ff0000 !important;
  margin: 0 5px;
}

.Noaccesspage {
  height: calc(100vh - 80px);
  width: 100vw;
  background: linear-gradient(
    45deg,
    #f8f7ed 12.5%,
    #fff 12.5%,
    #fff 37.5%,
    #f8f7ed 37.5%,
    #f8f7ed 62.5%,
    #fff 62.5%,
    #fff 87.5%,
    #f8f7ed 87.5%
  );
  background-size: 100px 100px;
  background-position: 50px 50px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.Noaccesspage_div {
  clip-path: polygon(100% 0, 100% 70%, 50% 100%, 0% 70%, 0 0);
  background-color: rgb(76 75 27 / 59%);
  border-radius: 10px 10px 0 0;
  width: 400px;
  max-width: 90%;
  padding: 15px;
  text-align: center;
}

.Noaccesspage_div h2 {
  margin: 0;
  padding: 0;
  font-size: 40px;
  line-height: 46px;
  color: #fff;
  position: relative;
}

.Noaccesspage_div h2::before {
  content: "";
  width: 120px;
  height: 5px;
  background: #fff;
  position: absolute;
  left: 50%;
  bottom: -10px;
  transform: translateX(-50%);
}

.Noaccesspage_div p {
  text-align: center;
  font-size: 22px;
  color: #fff;
  margin: 25px 0 0 0;
  padding: 0;
}
.Noaccesspage_div svg {
  color: #fff;
  width: 80px;
  height: 80px;
  margin-top: 10px;
}

.landingmodal {
  width: 100vw;
  height: 100vh;
  display: table-cell;
  align-items: center;
  vertical-align: middle;
}

.notes_details .MuiPaper-root,
.view_details .MuiPaper-root {
  box-shadow: none !important;
}

@media only screen and (max-width: 1199.98px) {
  .PatientSearch .searchForm .MuiButton-contained {
    margin: 0 auto;
    font-size: 25px;
    padding: 5px 10px;
    width: 250px;
  }

  .notes_details table,
  .notes_details tbody,
  .notes_details td,
  .notes_details tr {
    display: block;
    width: 100%;
    min-width: inherit !important;
  }

  .notes_details tr {
    margin: 10px 0;
    border: solid 1px #ccc;
  }

  .notes_details tr:first-of-type {
    margin-top: 0px;
  }

  .notes_details thead {
    display: none;
  }

  .notes_details td {
    position: relative;
    padding-left: 50% !important;
  }

  .notes_details td:before {
    content: attr(title);
    position: absolute;
    left: 0;
    padding-left: 10px;
    font-weight: bold;
  }

  .notes_details .MuiDialogContent-root {
    padding: 15px;
  }
  .userForm .inputblock2line {
    flex: 1 0 48% !important;
  }
  .MuiAutocomplete-root {
    min-width: inherit !important;
  }
}

@media only screen and (max-width: 991px) {
  .PatientSearch .searchForm .MuiFormControl-root {
    margin: 5px 0 !important;
  }
  .PatientSearch .inputblock2line {
    flex: 1 0 90%;
    margin: 10px 0;
  }
}
@media only screen and (max-width: 679px) {
  .userForm .inputblock2line {
    flex: 1 0 98% !important;
  }
  .list_search .list_search_item {
    flex: 1 0 100% !important;
  }
  .list_search .inputBlock {
    flex: 1 0 100% !important;
  }
}

@media only screen and (max-width: 599px) {
  .view_details .MuiDialogContent-root {
    padding: 20px 10px;
  }
  .view_details .MuiTypography-root {
    padding: 20px 10px !important;
  }
  .property_container .property_value {
    width: 45%;
  }
  .property_container .property_label {
    width: 50%;
  }
  .view_details .property_container {
    font-size: 13px !important;
  }
}

@media only screen and (max-width: 530px) {
  .property_value {
    width: 48%;
  }
}

@media only screen and (max-width: 499px) {
  .searchForm .MuiBox-root {
    margin: 0 5px;
  }
  .searchBarHeading {
    font-size: 25px;
  }
  .searchBarHead .MuiIconButton-root {
    background: var(--white);
    width: 30px;
    height: 30px;
    top: 2px;
  }
}

@media only screen and (max-width: 440px) {
  .BoxMui_modal .closeModalBtn,
  .modalCloseBtn,
  .close_icon_btn {
    top: -25px;
    right: -20px;
  }
}

/* /----------preview modal---------/ */

.listflexblock li {
  display: flex;
  justify-content: space-between;
  text-align: left;
  padding: 10px;
  word-break: break-word;
  font-size: 16px;
  line-height: 20px;
  align-items: center;
}

.listflexblock li strong {
  width: 25%;
}

.listflexblock li span {
  display: block;
  width: 65%;
}

.listflexblock li:nth-child(odd) {
  background: #d7d7d74f;
}

.userList_modal .BoxMui_modal {
  width: 600px;
  max-width: 94%;
}

.userList_modal .BoxMui_modal .listflexblock {
  height: 400px;
  overflow-y: scroll;
}

.heading h2 {
  font-size: 35px;
  font-weight: 600;
  color: #fff;
  background: #0569ad;
  padding: 0 10px
}

.heading h3 {
  font-size: 21px;
  font-weight: 600;
  color: #fff;
  background: #0569ad;
}

.notes_details .MuiDialogTitle-root + .css-ypiqx9-MuiDialogContent-root {
  padding-bottom: 0 !important;
}

@media only screen and (max-width: 440px) {
  .listflexblock li {
    font-size: 13px;
    line-height: 16px;
  }
  .listflexblock li strong {
    width: 32%;
  }
  .listflexblock li span {
    width: 57%;
  }
  .BoxMui_modal .heading h2 {
    font-size: 26px;
    padding: 5px !important;
  }
}

/* /--------snackbar css---------/ */

.MuiSnackbar-root {
  width: 100% !important;
  transform: inherit !important;
}

.MuiSnackbarContent-message {
  text-align: center !important;
}
.MuiSnackbarContent-root {
  display: block !important;
}
.MuiSnackbarContent-action {
  display: none !important;
}
/* /-------------search modal css-----/ */

/* /-----------add landing page css---------/ */

.thumbnail h1 {
  font-size: 22px;
  font-weight: 600;
}

.upload-container .MuiFab-circular {
  width: 40px;
  height: 40px;
}

/* /-----------add landing page css---------/ */

/* <-----------Navigation Menu list CSS---------> */

.addVid_dropDwn .MuiPopover-paper .MuiMenu-list li:not(:last-child) {
  border-bottom: 1px solid #cccccca1;
}
.addVid_dropDwn .MuiPopover-paper .MuiMenu-list li li {
  padding: 0 !important;
  border: none !important;
}
.addVid_dropDwn .MuiPopover-paper .MuiMenu-list li li button {
  padding: 0;
  color: #2e2e2e;
  text-transform: none;
}
.addVid_dropDwn .MuiPopover-paper .MuiMenu-list li span {
  display: block;
  width: 100%;
}
/* <-----------Navigation Menu list CSS---------> */

/*-----Backend top part -------------*/

.header_menu_block {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.admin_topRight {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  border-left: 1px solid #cccccf;
  padding-left: 25px;
}

.header_centerblock {
  width: 100%;
}

.toggle_button {
  display: none;
}

.toggle_button button {
  align-items: center;
  background-color: var(--orange);
  border: none;
  border-radius: 0;
  cursor: pointer;
  display: flex;
  height: 50px;
  padding: 0;
  justify-content: center;
  width: 50px;
  margin-right: 20px;
}

.toggle_button button svg {
  color: #fff;
}

.header_menu_block ul li button span svg {
  display: block;
}

.topuserblock {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
}

.topuserblock .PersonIcon {
  background-color: var(--deep-blue);
  border-radius: 100px;
  color: #fff;
  height: 30px;
  padding: 5px;
  width: 30px;
}

.topuserblock .userarrowicon {
  background-color: var(--deep-blue);
  border-radius: 100px;
  color: #fff;
  height: 20px;
  margin: 0;
  padding: 0;
  width: 20px;
}

.name_drop {
  align-items: center;
  display: flex;
  justify-content: space-between;
  min-width: inherit;
}

.name_drop button {
  min-width: inherit;
}

.header_menu_block ul {
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  height: 100%;
  padding: 10px 0;
}

.header_menu_block ul li {
  list-style: none;
  margin-bottom: 10px;
}

.header_menu_block ul li a {
  list-style: none;
  color: #000;
  text-decoration: none;
  margin: 0 5px;
  font-size: 16px;
  border-radius: 5px;
  padding: 6px 10px;
}

.header_menu_block a.active,
.header_menu_block a:hover,
.header_menu_block button.active {
  background-color: var(--orange);
  color: #fff;
}

.header_centerblock button {
  color: var(--black);
  font-weight: 400;
  text-transform: capitalize;
  font-size: 16px;
  padding: 4px 10px;
}

.header_centerblock button:hover {
  color: var(--white);
  background: var(--orange);
}

.BackendHeader {
  background-color: #e4e9eb;
  margin: 0 -25px;
  padding: 0px 25px;
  border-bottom: 1px solid #cccccf;
}

/* .MuiMenu-paper {
    margin-top: 40px;
} */

.MuiMenu-list {
  padding: 0 !important;
}

.userDetail_dropDwn li:hover {
  background-color: var(--orange) !important;
  color: var(--white) !important;
}

.userDetail_dropDwn li a:hover {
  color: var(--white) !important;
}

.headerLogo {
  width: 200px;
  border-right: 1px solid #cccccf;
  padding: 10px 0;
  padding-right: 25px;
}

.headerLogo img {
  width: 90%;
}

.userDetail_dropDwn ul.MuiMenu-list {
  padding: 0px;
  min-width: 180px;
  max-width: 200px;
}

.userDetail_dropDwn h1 {
  margin: 0;
  padding: 10px;
  font-size: 20px;
  color: #fff;
  background-color: var(--blue2);
  font-weight: 500 !important;
  border-bottom: 1px solid #cccccf;
  text-align: center;
}

.userDetail_dropDwn li {
  list-style: none;
  border-bottom: 1px solid #cccccf;
  display: block;
  text-align: center;
  width: 100%;
  font-size: 18px !important;
  font-weight: 400 !important;
}

.userDetail_dropDwn li a {
  text-decoration: none;
  color: #333;
  display: block;
  text-align: center;
  width: 100%;
  text-transform: inherit !important;
  font-size: 18px !important;
  font-weight: 400 !important;
}

.headerRight {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: calc(100% - 220px);
  flex-direction: row-reverse;
}

.page_heading {
  margin: 25px 0;
  padding: 10px;
  font-size: 24px;
  color: #333;
  font-weight: 500;
  border-left: solid 5px var(--blue2);
  background: #fff;
  box-shadow: 0 5px 20px rgb(0 0 0 / 5%);
  border-radius: 5px;
}

.hideTop .page_heading {
  margin-top: 40px;
}

.dataform1 {
  display: block;
  position: relative;
}

.dataform1_wrapper {
  background: #fff;
  border-radius: 8px;
  display: block;
  box-shadow: 4px 4px 25px hsla(0, 1.9%, 42%, 0.15);
  padding: 25px;
  width: 800px;
  margin-bottom: 25px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
}

.dataform2_wrapper {
  background: #fff;
  border-radius: 8px;
  display: block;
  box-shadow: 4px 4px 25px hsla(0, 1.9%, 42%, 0.15);
  padding: 25px;
  margin-bottom: 25px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  position: relative;
}

.dataform {
  display: block;
  position: relative;
}

.dataform_wrapper {
  background: #fff;
  border-radius: 8px;
  display: block;
  box-shadow: 4px 4px 25px hsla(0, 1.9%, 42%, 0.15);
  padding: 25px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
}

.header_menu_block ul li button {
  list-style: none;
  color: #000;
  text-decoration: none;
  margin: 0 5px;
  font-size: 16px;
  border-radius: 5px;
  padding: 9px 10px;
  border: none;
  outline: none;
  background: none;
  cursor: pointer;
}

.header_menu_block ul li button:hover {
  background-color: var(--orange);
  color: #fff;
}

.header_menu_block ul li button svg {
  display: none;
}

/* .MuiPopover-root .MuiPaper-elevation {top: 46px !important;} */

.BackendHeader .left_menu_svg {
  display: none;
}

.header_menu_block .MuiButton-root {
  padding: 5px 10px !important;
}

.topmenu .MuiPaper-root {
  margin-top: 30px;
}
.topmenu .MuiMenu-list .MuiMenuItem-gutters,
.topmenu .MuiButtonBase-root {
  font-size: 16px;
  font-weight: 400;
}
/* <-------------Backend Header List Menu Orientation CSS--------------> */

.childMenuActive .MuiMenu-paper {
  margin-left: 155px;
}

/* <-------------Backend Header List Menu Orientation CSS--------------> */

/*-----Backend footer part -------------*/

.footerBody {
  margin-top: auto;
  background-color: var(--white);
  text-align: center;
  color: var(--gray2);
  padding: 15px;
  font-size: 16px;
  box-shadow: 0 5px 20px rgb(0 0 0 / 5%);
  border-radius: 5px;
}

.footerBody span {
  padding: 0 5px;
  display: inline-block;
}

.showTop .BackendLeft {
  display: none;
}

.hideTop .BackendHeader {
  display: none;
}

.showTop .BackendBody {
  padding-left: 25px;
}

.footer p {
  text-align: center;
  font-size: 16px;
  line-height: 19px;
  color: #979fa7;
}

.footer p span {
  padding: 5px;
}

.footer p span a {
  color: #979fa7;
  text-decoration: none;
}

.footer p span a:hover {
  color: #000;
}

.footer {
  padding: 20px 0;
  background-color: #e0e7ee;
}
.footercontainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.footerloginbtn {
  font-size: 20px;
  padding: 6px 30px;
  border-radius: 5px;
  background: #ddd;
  background: linear-gradient(
    180deg,
    rgba(8, 231, 252, 1) 0%,
    rgba(39, 134, 234, 1) 100%
  );
  color: #fff;
  border: 1px solid #42c3f7;
  cursor: pointer;
  box-shadow: 4px 4px 5px #0268875e;
  transition: all 0.3s;
  font-weight: var(--main-font-SemiBold);
  text-shadow: 1px 1px #087cad;
}
.footerloginbtn:hover {
  box-shadow: 4px 5px 6px #166f9fad;
}

.footer p span button {
  color: #979fa7;
  text-decoration: none;
  background: none;
  cursor: pointer;
  border: none;
  text-align: center;
  font-size: 16px;
  line-height: 19px;
}

.footer p span button:hover {
  color: #000;
}

.folder_view_body {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.folder_view_left,
.folder_view_right {
  width: 290px;
  background-color: var(--gray1);
  padding: 15px;
  border-radius: 10px;
}

/* .folder_view_left,.folder_view_right .blocks{margin: 10px 0;} */

.folder_view_left h3,
.folder_view_right h3 {
  background-color: var(--white);
  color: #333;
  padding: 15px;
  font-size: 18px;
  box-shadow: 0 5px 20px rgb(0 0 0 / 5%);
  border-radius: 5px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  font-weight: 600;
}

.folder_view_left p {
  margin: 5px 0;
  border-bottom: solid 1px #ccc;
  display: flex;
  flex-wrap: wrap;
  line-height: 20px;
  padding: 6px 0;
  color: #333;
}

.folder_view_left p strong {
  color: var(--orange);
  font-weight: 600;
  width: 80px;
}

.folder_view_left p span {
  width: calc(100% - 85px);
}

.email {
  word-break: break-all;
}

.folder_view_con {
  width: calc(100% - 620px);
  border: solid 1px #e5e5e1;
  padding: 15px;
  border-radius: 10px;
}

.folder_view_left button {
  position: relative;
  padding: 8px;
  margin: 5px 0;
  border-radius: 10px;
  font-size: 18px;
  font-weight: 600;
  color: #fff;
  text-decoration: none;
  transition: all 0.1s;
  -webkit-transition: all 0.1s;
  background-color: #3498db;
  border-bottom: 5px solid #2980b9;
  text-shadow: 0px -2px #2980b9;
  display: block;
  width: 100%;
  text-transform: capitalize;
}

.folder_view_left button:last-of-type {
  margin-bottom: 0;
}

.folder_view_left button:hover {
  background-color: #f2cf66;
  border-bottom: 5px solid #d1b358;
  text-shadow: 0px -2px #d1b358;
  color: #333;
  text-shadow: 0px -2px #d1b358;
}

.folder_view_left button:active {
  transform: translate(0px, 5px);
  -webkit-transform: translate(0px, 5px);
  border-bottom: 5px solid #fff;
}

.folder_view_left .active_btn {
  background-color: #f2cf66;
  border-bottom: 5px solid #d1b358;
  text-shadow: 0px -2px #d1b358;
  color: #333;
  text-shadow: 0px -2px #d1b358;
}

.folder_view_right h3 svg,
.folder_view_left h3 svg {
  border-radius: 100px;
  margin-right: 10px;
  width: 30px;
  height: 30px;
  color: #fff;
  background: #333;
  padding: 5px;
}

.folder_view_con h3 {
  background-color: #f2cf66;
  color: #333;
  margin-bottom: 10px;
  padding: 15px;
  font-size: 22px;
  box-shadow: 0 5px 20px rgb(0 0 0 / 5%);
  border-radius: 5px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  font-weight: 600;
}

.folder_view_con h3 svg {
  border-radius: 100px;
  margin-right: 10px;
  width: 35px;
  height: 35px;
  color: #fff;
  background: #333;
  padding: 5px;
}

.folder_view_con p {
  margin: 0;
  border-bottom: solid 1px #ebebeb;
  display: flex;
  flex-wrap: wrap;
  line-height: 20px;
  padding: 10px;
  color: #333;
}

.folder_view_con p strong {
  color: var(--orange);
  font-weight: 600;
  width: 320px;
}

.folder_view_con p:nth-of-type(odd) {
  background: #ebebeb;
}
.paragraph p {
  background: none !important;
  display: block;
  padding: 0;
  margin: 5px 0;
  border: none;
  color: #0009;
}
.blue_text,
.blue_text p {
  color: #1976d2;
}

.folder_view_con p span {
  width: calc(100% - 320px);
}

.folder_view_con p span span {
  width: auto;
}

.left_centerblock .leftArrowIcon {
  width: 20px;
  height: 20px;
}

.leadlistcon_wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 -5px;
}
.leadlistcon {
  display: block;
  margin: 5px;
  flex: 1 1 48%;
  background: #f3f3f3;
  padding: 10px;
  border: 1px solid #e7e6e1;
}

.leadlistcon h4 {
  font-weight: normal;
}
.leadlistcon h4 strong {
  font-weight: 500;
  padding-right: 10px;
  color: var(--orange);
}

@media only screen and (max-width: 1660.98px) {
  .folder_view_con .formInline .inputBlock {
    width: 100% !important;
    flex: inherit !important;
  }
  .folder_view_con .formInline .statusinputBox {
    flex: 40% !important;
  }
}

@media only screen and (max-width: 1499px) {
  .header_menu_block ul li button {
    margin: 0 3px;
    padding: 5px;
  }
  .header_menu_block .MuiButton-root {
    padding: 0 4px !important;
  }
  .header_menu_block .MuiButton-endIcon {
    margin-left: 2px;
  }
  .headerLogo {
    width: 170px;
    padding-right: 0;
  }
  .headerRight {
    width: calc(100% - 170px);
  }
}

@media only screen and (max-width: 1300.98px) {
  .folder_view_con h3 {
    font-size: 20px;
  }
  .folder_view_con h3 svg {
    width: 30px;
    height: 30px;
  }
  .folder_view_con .formInline .statusinputBox {
    flex: inherit !important;
  }
  .folder_view_left,
  .folder_view_right {
    width: 300px;
  }
  .folder_view_left h3,
  .folder_view_right h3 {
    padding: 10px;
    font-size: 20px;
  }
  .folder_view_right h3 svg,
  .folder_view_left h3 svg {
    width: 30px;
    height: 30px;
  }
  .folder_view_con {
    width: calc(100% - 640px);
  }
}

@media only screen and (max-width: 1199.98px) {
  .folder_view_left_btn {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .folder_view_left button {
    width: 49%;
  }
  .folder_view_body {
    display: block;
  }
  .folder_view_left,
  .folder_view_right {
    width: auto;
  }
  .folder_view_con {
    width: auto;
    margin: 15px 0;
  }
  .hideTop .page_heading {
    margin-top: 25px;
  }
  .hideTop .rolelist_mainwrp {
    margin-top: 0;
  }
  .BackendHeader .MuiBox-root {
    margin-left: 0;
  }
  .dataform_wrapper {
    padding: 15px;
  }
  .dataform1_wrapper {
    padding: 15px;
  }
  .toggle_button {
    display: block;
  }
  .header_centerblock ul {
    display: none;
  }
  .showdiv ul {
    display: flex;
  }
  .admin_topRight {
    border-left: none;
    padding-left: 0;
    position: absolute;
    right: 10px;
    top: 10px;
  }
  .header_centerblock {
    display: block;
    width: 100%;
  }
  .headerRight {
    display: block;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    flex-direction: row-reverse;
  }
  .showdiv ul {
    display: block;
    border-top: 1px solid #cccccf;
  }
  .header_menu_block ul li {
    display: block;
  }
  .header_menu_block ul li a {
    display: block;
    text-align: center;
    border-radius: 0px;
    border-top: 1px solid #cccccf;
  }
  .BackendHeader {
    padding: 0;
  }
  .header_menu_block ul li a {
    margin: 0;
  }
  .headerLogo {
    padding-left: 25px;
    width: 225px;
  }
  .hideTop .BackendHeader {
    display: none;
  }
  .hideTop .BackendLeft {
    display: none;
  }
  .hideTop .BackendHeader {
    display: block;
  }
  .BackendBody {
    padding: 0;
    width: auto;
  }
  .showTop .BackendBody {
    padding-left: 0px;
  }
  .BackendHeader {
    margin: 0;
  }
  .BackendOutlet {
    padding: 15px;
  }
  .header_menu_block ul li button {
    display: block;
    width: 100%;
    border-top: 1px solid #cccccf;
    margin: 0;
    border-radius: 0px;
    padding: 6px 0;
    font-size: 18px;
  }
  .header_centerblock button {
    width: 100%;
    border-top: 1px solid #cccccf;
  }
  .MuiMenu-paper {
    max-width: 100% !important;
    width: 100%;
    left: 0 !important;
  }
  .header_menu_block .MuiButton-root {
    display: flex;
  }
  /* .topmenu .MuiPaper-root {
        top: 310px !important;
    } */
  .topmenu .MuiPaper-root li {
    display: block;
    text-align: center;
    font-size: 18px;
    margin: 0;
    padding: 6px 0;
  }
  .userDetail_dropDwn ul.MuiMenu-list {
    max-width: initial;
  }
}

@media only screen and (max-width: 991.98px) {
  .dataform1_wrapper {
    width: 100%;
  }
  .rolelist_mainwrp_Table {
    padding: 15px;
  }
}

@media only screen and (max-width: 780px) {
  .lastLogin_Info .BoxMui_modal {
    width: 94%;
  }
}

@media only screen and (max-width: 640px) {
  .folder_view_left_btn {
    display: block;
  }
  .folder_view_left button {
    width: 100%;
  }
  .rolelist_mainwrp_Table {
    padding: 15px;
  }
  .footercontainer {
    display: block;
  }
  .footerloginbtn {
    margin: 0 auto;
    display: block;
    margin-top: 20px;
  }
}

@media only screen and (max-width: 560px) {
  .MuiDialogTitle-root {
    padding: 20px 30px !important;
  }
  .BoxMui_modal h2 {
    padding: 10px !important;
  }
  .BoxMui_modal {
    width: 90% !important;
  }
}

@media only screen and (max-width: 400.98px) {
  .header_menu_block {
    display: block;
  }
  .admin_topRight {
    border-left: none;
    padding-left: 0;
    position: inherit;
    right: 0;
    top: 0;
    display: block;
    width: 90%;
    margin: 0 5%;
  }
  .topuserblock {
    justify-content: space-between;
    width: 100%;
  }
  .toggle_button button {
    margin-right: 0px;
  }
  .headerLogo {
    padding: 0;
    border: none;
    width: auto;
    text-align: center;
  }
  .headerLogo img {
    max-width: 280px;
    margin: 10px auto;
  }
  .folder_view_left h3,
  .folder_view_right h3 {
    flex-direction: column;
  }
  .folder_view_con h3 {
    font-size: 18px;
    padding: 10px;
  }
  .folder_view_con h3 svg {
    width: 26px;
    height: 26px;
  }
  .folder_view_left button {
    line-height: 30px;
  }
}

/* /-----------table css start by subhashis............../ */

.trainingCenter_main {
  margin-left: 0px !important;
  width: 100% !important;
  justify-content: space-between;
  padding: 0px !important;
  margin-top: 0 !important;
}

.commonWidth_admin {
  width: 1764px;
  margin: 0 auto;
}

.trainingCenter_Sub {
  /* margin-left: 0px !important; */
  width: 100% !important;
  justify-content: space-between;
  /* padding: 20px !important; */
  display: flex;
  margin: 20px 0 !important;
}

.trainingCenter_Sub .MuiGrid-grid-xl-8 {
  width: 70% !important;
  max-width: inherit !important;
  flex-basis: inherit !important;
}

.commonWidth_admin .MuiGrid-root {
  margin-left: auto !important;
  margin-right: auto !important;
  margin-top: 0 !important;
}

.traingcenterdata_wrapper {
  /* padding: 15px; */
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-left: 0 !important;
  padding-top: 0px !important;
}

.traingcenterdata_wrapper app-traing-center {
  /* margin: 0.5%; */
  border-radius: 0px;
  box-shadow: -1px 1px 6px #0000008f;
  width: 100%;
  transition: transform 0.2s;
  background: rgb(28 25 127);
  background: linear-gradient(138deg, rgb(28 24 126) 0%, rgb(9 39 72) 100%);
  padding: 0;
  border: 1px solid #f5f5f5;
}

.traingcenterdata_wrapper .tncgCenter_dta {
  background-color: #ffffff;
  border-radius: 0px;
}

.traingcenterdata_wrapper app-traing-center .tncgCenter_dta {
  margin: 5px;
}

.tittle_wrapper {
  background: linear-gradient(180deg, #25cfe9 27%, #42b3f5 58%);
  display: flex;
  align-items: center;
}

.ongoingg .tittle_wrapper p {
  color: #fff;
  text-shadow: 1px 1px 5px #221f06;
}

.ongoing .tittle_wrapper {
  background: rgb(236, 224, 80);
  background: linear-gradient(
    180deg,
    rgba(236, 224, 80, 1) 0%,
    rgba(201, 195, 56, 1) 100%
  );
}

.traingcenterList_wrapper app-traing-center,
.trainingCenter_main .tittle_wrapper {
  cursor: pointer;
}

.trainingDesc_modal .test_html_class {
  /* {background: linear-gradient(184deg,#ffffff 27%,#c0b9b9 67%); */
  background-color: #dbe4eb;
  background-repeat: no-repeat;
}

/* overflow-y: scroll;} */

/* height: 80vh;} */

.tittle_wrapper,
.description_wrapper,
.mad_button_wrapper {
  padding: 1%;
  /* width: 98%; */
}

.traingcenterdata_wrapper
  .tncgCenter_dta
  .media_content_wrapper
  .media_content {
  overflow: hidden;
  height: auto;
  padding: 8px;
  background: rgb(83, 91, 94);
  background: linear-gradient(
    138deg,
    rgb(127 134 137) 0%,
    rgba(41, 52, 58, 1) 100%
  );
  width: auto;
  position: relative;
}

.media_content video {
  object-fit: cover;
  height: 100%;
  width: 100%;
}

.media_content[_ngcontent-umg-c107] video[_ngcontent-umg-c107] {
  width: 100%;
}

.mediacontrol_wrapper[_ngcontent-umg-c107] {
  padding: 0 15px;
}

.traingcenterdata_wrapper .mediacontrol_wrapper {
  padding: 0 !important;
  margin: 0;
}

.mediacontrol_icon_wrapper[_ngcontent-umg-c107] {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.traingcenterdata_wrapper .mediacontrol_icon_wrapper {
  background: #fff;
  padding: 2px;
}

.mediacontrol_wrapper .mediacontrol_icon_wrapper span {
  display: flex;
  /* align-items: center; */
}

.mediacontrol_wrapper .mediacontrol_icon_wrapper span {
  display: flex;
  /* align-items: center; */
}

.traingcenterdata_wrapper .mat-progress-bar-fill::after {
  background-color: #fcd602;
}

.tittle_wrapper,
.description_wrapper,
.mad_button_wrapper {
  padding: 15px;
  /* padding: 15px 15px 0; */
  /* width: 98%; */
}

.traingcenterdata_wrapper .mad_button_wrapper {
  display: flex;
  justify-content: flex-end;
  padding-top: 0;
}

.description_wrapper {
  padding-bottom: 5px;
}

.description_wrapper p {
  font-size: 18px;
  line-height: 22px;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  text-transform: capitalize;
}

.mad_button_wrapper button {
  background-color: #00c8f5;
  padding: 10px 15px;
  border-radius: 5px;
  font-size: 18px;
  color: #fff;
  font-weight: 600;
  border: none;
  margin: 2px;
  cursor: pointer;
}

.traingcenterdata_wrapper app-traing-center {
  /* margin: 0.5%; */
  border-radius: 0px;
  box-shadow: -1px 1px 6px #0000008f;
  width: 100%;
  transition: transform 0.2s;
  background: rgb(28 25 127);
  background: linear-gradient(138deg, rgb(28 24 126) 0%, rgb(9 39 72) 100%);
  padding: 0;
  border: 1px solid #f5f5f5;
}

.PatientSearch .joindate .MuiFormControl-root {
  width: 48.5%;
}

/* /-----------table css end by subhashis............../ */

/* <-----------------Calender Success Banner CSS Starts *Sanket*--------------------> */

.calender_banner_mainwrapper {
  background-image: url(https://all-frontend-assets.s3.amazonaws.com/aspire-pages-banner/calender_banner_bg.webp);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.calender_banner_subwrapper {
  background-image: url(https://all-frontend-assets.s3.amazonaws.com/aspire-pages-banner/calender_banner_img.webp);
  background-repeat: no-repeat;
  background-size: auto 100%;
  background-position: right bottom;
}
.calender_banner_subwrapper {
  padding: 54px 0;
}
.calender_banner_blockwrap {
  display: flex;
  justify-content: space-between;
  width: 67%;
  padding: 50px 10px 55px 25px;
  background: url(https://all-frontend-assets.s3.amazonaws.com/aspire-pages-banner/calender_banner_block_whlbg.webp),
    #ffffff;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  border: 1px solid #fff;
  border-radius: 15px;
  align-items: center;
}
.calender_banner_mainwrapper .calender_block_left {
  width: 37%;
}
.calender_banner_mainwrapper .calender_block_left img {
  display: block;
  width: 100%;
}
.calender_banner_mainwrapper .calender_block_right {
  width: 55%;
}
.calender_banner_mainwrapper .calender_block_right h1 {
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 1) 50%,
    rgba(200, 210, 208, 1) 45%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-transform: uppercase;
  font-size: 41px;
  filter: drop-shadow(0 3px 5px rgba(0, 0, 0, 0.4));
  font-weight: var(--main-font-Bold);
  text-align: center;
}
.calender_banner_mainwrapper .calender_block_right p {
  font-size: 27px;
  line-height: 31px;
  color: #00f2ee;
  font-weight: var(--main-font-Medium);
  padding-left: 45px;
}

/* <-----------------Calender Success Banner CSS Ends *Sanket*--------------------> */

/* <-----------------Calender Success Banner Responsive Starts *Sanket*--------------------> */

@media only screen and (max-width: 1799px) {
  .calender_banner_mainwrapper .commonWidth_admin {
    width: 96%;
  }
}
@media only screen and (max-width: 1748px) {
  .calender_banner_mainwrapper .calender_block_right h1 {
    font-size: 39px;
  }
  .calender_banner_mainwrapper .calender_block_right {
    width: 54%;
  }
  .calender_banner_mainwrapper .calender_block_right p {
    font-size: 25px;
    line-height: 29px;
  }
  .calender_banner_mainwrapper .calender_block_left {
    width: 35%;
  }
}
@media only screen and (max-width: 1658px) {
  .calender_banner_blockwrap {
    align-items: center;
    width: 65%;
    padding: 35px;
  }
  .calender_banner_mainwrapper .calender_block_right h1 {
    font-size: 37px;
  }
  /* .calender_banner_mainwrapper .calender_block_left {width: 33%;} */
  .calender_banner_mainwrapper .calender_block_right {
    width: 55%;
  }
  .calender_banner_mainwrapper .calender_block_right h1 {
    font-size: 34px;
  }
  .calender_banner_mainwrapper .calender_block_right p {
    font-size: 22px;
    line-height: 26px;
  }
}
@media only screen and (max-width: 1458px) {
  .calender_banner_mainwrapper .calender_block_right p {
    font-size: 19px;
    line-height: 23px;
  }
  .calender_banner_mainwrapper .calender_block_right h1 {
    font-size: 30px;
  }
}
@media only screen and (max-width: 1299px) {
  .calender_banner_blockwrap {
    padding: 25px;
  }
  .calender_banner_mainwrapper .calender_block_right h1 {
    font-size: 28px;
  }
  .calender_banner_mainwrapper .calender_block_right p {
    padding-left: 35px;
  }
  .calender_banner_mainwrapper .calender_block_right p {
    font-size: 18px;
    line-height: 20px;
  }
}
@media only screen and (max-width: 1199px) {
  .calender_banner_mainwrapper .calender_block_right p {
    font-size: 16px;
    line-height: 18px;
    padding-left: 25px;
  }
  .calender_banner_mainwrapper .calender_block_right h1 {
    font-size: 26px;
  }
}
@media only screen and (max-width: 1099px) {
  .calender_banner_blockwrap {
    width: 70%;
  }
  .calender_banner_subwrapper {
    background-position: right -70px bottom;
  }
  .calender_banner_mainwrapper .calender_block_right h1 {
    font-size: 24px;
  }
}
@media only screen and (max-width: 968px) {
  .calender_banner_mainwrapper .calender_block_right h1 {
    font-size: 22px;
  }
  .calender_banner_mainwrapper .calender_block_right p {
    font-size: 14px;
    line-height: 16px;
    padding-left: 20px;
    text-align: center;
  }
  .calender_banner_subwrapper {
    background-position: right -80px bottom;
  }
}
@media only screen and (max-width: 899px) {
  .calender_banner_mainwrapper {
    background: linear-gradient(
      90deg,
      rgba(5, 55, 207, 1) 16%,
      rgba(40, 216, 200, 1) 88%
    );
  }
  .calender_banner_subwrapper {
    background: none;
    padding: 30px 0;
  }
  .calender_banner_blockwrap {
    width: 100%;
  }
}
@media only screen and (max-width: 628px) {
  .calender_banner_blockwrap {
    flex-direction: column;
    background: #fff;
    padding: 15px;
  }
  .calender_banner_mainwrapper .calender_block_left {
    width: 46%;
  }
  .calender_banner_mainwrapper .calender_block_right {
    width: 100%;
    margin-top: 15px;
    padding: 15px 10px;
    background: var(--blue2);
  }
  .calender_banner_mainwrapper .calender_block_right h1,
  .calender_banner_mainwrapper .calender_block_right p {
    text-align: center;
    padding-left: 0;
  }
  .calender_banner_mainwrapper .calender_block_right p {
    font-size: 16px;
  }
  .calender_banner_mainwrapper .calender_block_right p br {
    display: none;
  }

  .hmmodal_user_Box {
    min-width: inherit;
  }
}
@media only screen and (max-width: 448px) {
  .calender_banner_mainwrapper .calender_block_right h1 {
    font-size: 20px;
  }
}
@media only screen and (max-width: 399px) {
  .calender_banner_mainwrapper .calender_block_right p {
    font-size: 12px;
    line-height: 14px;
  }
  .calender_banner_mainwrapper .calender_block_right h1 {
    font-size: 19px;
    line-height: 25px;
  }
  .calender_banner_mainwrapper .calender_block_left {
    width: 54%;
  }
  .calender_banner_mainwrapper .calender_block_right p {
    margin-top: 5px;
  }
}
@media only screen and (max-width: 340px) {
  .calender_banner_mainwrapper .calender_block_left {
    width: 60%;
  }
  .calender_banner_mainwrapper .calender_block_right h1 {
    font-size: 17px;
    line-height: 23px;
  }
}

/* <-----------------Calender Success Banner Responsive Ends *Sanket*--------------------> */

/* <----------------------Additional Training Center CSS------------------------> */

.additional_trainingCenter_Sub
  .traingcenterlist_wrapper
  .cat_block
  .cat_block_cat_data {
  font-size: 22px;
  line-height: 24px;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  text-transform: capitalize;
  display: flex;
  align-items: center;
  padding: 10px;
  color: #fff;
  cursor: pointer;
  border-radius: 5px;
}

.additional_trainingCenter_Sub
  .traingcenterlist_wrapper
  .cat_block
  .cat_block_cat_data
  svg {
  margin-right: 8px;
}

.additional_trainingCenter_Sub
  .traingcenterlist_wrapper
  .cat_block:not(:first-child) {
  margin-top: 8px;
}

/* .additional_trainingCenter_Sub .traingcenterlist_wrapper .cat_block .cat_block_lesson_data {margin-left: 20px;} */

.additional_trainingCenter_Sub
  .traingcenterlist_wrapper
  .cat_block
  .cat_block_lesson_data
  .lessoncontent {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-top: 4px;
  border-radius: 5px;
  padding: 5px;
}

.additional_trainingCenter_Sub
  .traingcenterlist_wrapper
  .cat_block
  .cat_block_lesson_data
  .lessoncontent
  > img {
  display: block;
  width: 20%;
  border-radius: 5px;
}

.additional_trainingCenter_Sub
  .traingcenterlist_wrapper
  .cat_block
  .cat_block_lesson_data
  .lessoncontent
  > p {
  margin-left: 20px;
  font-size: 18px;
  line-height: 22px;
  font-weight: 400;
  color: #fff;
}

.additional_trainingCenter_Sub
  .traingcenterlist_wrapper
  .cat_block
  .cat_block_lesson_data
  .lessoncontent.active {
  background: linear-gradient(
    180deg,
    rgba(236, 224, 80, 1) 0%,
    rgba(201, 195, 56, 1) 100%
  );
}

.additional_trainingCenter_Sub
  .traingcenterlist_wrapper
  .cat_block
  .cat_block_lesson_data
  .lessoncontent.active
  > p {
  color: #000;
}

.additional_trainingCenter_Sub
  .traingcenterlist_wrapper
  .cat_block.inactive
  .cat_block_cat_data {
  background: linear-gradient(180deg, rgb(79 195 123) 0%, rgb(10 82 29) 100%);
}

/* .additional_trainingCenter_Sub .traingcenterlist_wrapper .cat_block.cat_block_cat_data:hover {} */

.additional_trainingCenter_Sub
  .traingcenterlist_wrapper
  .cat_block.active
  .cat_block_cat_data {
  background: linear-gradient(180deg, rgb(40 96 61) 0%, rgb(9 74 26) 100%);
}

.additional_trainingCenter_Sub
  .traingcenterlist_wrapper
  .traingcenterlist_innerwrapper {
  width: 94%;
  margin: auto;
  position: sticky;
  top: 15px;
}

.additional_trainingCenter_Sub
  .traingcenterlist_wrapper
  .traingcenterlist_innerwrapper
  .readmorediv
  button {
  padding: 10px 25px;
  background-color: #000;
  color: #fff;
  font-size: 18px;
}

.additional_trainingCenter_Sub
  .traingcenterlist_wrapper
  .cat_block
  .cat_block_lesson_data
  .lessoncontent.inactive {
  background: linear-gradient(180deg, rgb(89 172 255) 0%, rgb(68 119 171) 100%);
}

.additional_trainingCenter_Sub
  .traingcenterlist_wrapper
  .cat_block
  .cat_block_lesson_data
  .lessoncontent.done {
  background: linear-gradient(
    180deg,
    rgba(68, 209, 61, 1) 0%,
    rgba(25, 148, 52, 1) 100%
  );
}
.trainingCenter_main .traingcenterdata_wrapper {
  display: flex !important;
}
.cat_block {
  margin-bottom: 8px;
}

.additional_trainingCenter_Sub .traingcenterdata_wrapper {
  display: block;
}

.additional_trainingCenter_Sub .traingcenterdata_wrapper .leftpart_wrapper {
  width: 98%;
  border: 5px solid #000;
  position: sticky;
  top: 15px;
  background-color: #fff;
}

.additional_trainingCenter_Sub
  .traingcenterdata_wrapper
  .leftpart_wrapper
  .leftpart_header {
  padding: 15px;
  background: linear-gradient(
    180deg,
    rgba(236, 224, 80, 1) 0%,
    rgba(201, 195, 56, 1) 100%
  );
}

.additional_trainingCenter_Sub
  .traingcenterdata_wrapper
  .leftpart_wrapper
  .leftpart_header
  h1 {
  font-weight: 600;
}

.additional_trainingCenter_Sub
  .traingcenterdata_wrapper
  .leftpart_wrapper
  .mediacontentpart
  > div {
  position: relative;
  width: 100%;
  overflow: hidden;
  padding-top: 56.25%;
}

.additional_trainingCenter_Sub
  .traingcenterdata_wrapper
  .leftpart_wrapper
  .mediacontentpart
  > div
  iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border: none;
}

.additional_trainingCenter_Sub
  .traingcenterdata_wrapper
  .leftpart_wrapper
  .addtnl_mediacontentpart {
  padding: 15px;
  padding-bottom: 0;
}

.additional_trainingCenter_Sub
  .traingcenterdata_wrapper
  .leftpart_wrapper
  .description {
  background-color: #fff;
  display: block;
}

.additional_trainingCenter_Sub
  .traingcenterdata_wrapper
  .leftpart_wrapper
  .description
  p {
  font-size: 18px;
}

.additional_trainingCenter_Sub
  .traingcenterdata_wrapper
  .leftpart_wrapper
  .nextbuttondiv {
  width: fit-content;
  margin: 5px 5px 5px auto;
}

.additional_trainingCenter_Sub
  .traingcenterdata_wrapper
  .leftpart_wrapper
  .nextbuttondiv
  button {
  padding: 5px 20px;
  color: #fff;
  font-size: 20px;
  background: linear-gradient(180deg, rgb(21 142 161) 0%, rgb(78 179 194) 100%);
}

.readmorediv {
  text-align: center;
  margin: 15px 0px;
}
.trainingCenter_main .disabled {
  background-color: #0000003d !important;
}

/* <----------------------Additional Training Center CSS------------------------> */

/* <--------------Training Material List CSS-----------> */

.tableDataTrainingMaterial
  .MuiTableContainer-root
  .MuiTable-root
  .MuiTableBody-root
  .MuiTableRow-root
  .program_names
  .program_block {
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  background-color: rgba(0, 0, 0, 0.08);
  padding: 6px 12px;
  font-size: 13px;
  border-radius: 16px;
}

.chip_block,
.program_block {
  display: inline-block;
  margin: 5px;
  text-overflow: ellipsis;
  white-space: nowrap;
  background-color: rgba(0, 0, 0, 0.08);
  padding: 6px 12px;
  font-size: 13px;
  border-radius: 16px;
  max-width: 105px;
  overflow: hidden;
}
.MuiDialog-container .chip_block,
.modalBaseStyle .chip_block, 
.modalBaseStyle .program_block, 
.MuiDialog-container .program_block{max-width: inherit;}

.contract_related_program .chip_block {
  display: inline-block;
  white-space: nowrap;
  background-color: rgba(0, 0, 0, 0.08);
  padding: 6px 12px;
  border-radius: 16px;
  max-width: fit-content;
  overflow: inherit;
  font-size: 15px;
}

/* <--------------Training Material List CSS-----------> */

@media only screen and (max-width: 1548px) {
  .additional_trainingCenter_Sub .traingcenterlist_wrapper {
    width: 28%;
  }
  .additional_trainingCenter_Sub
    .traingcenterlist_wrapper
    .cat_block
    .cat_block_lesson_data
    .lessoncontent
    > p {
    font-size: 16px;
    line-height: 22px;
  }
  /* .additional_trainingCenter_Sub .traingcenterlist_wrapper .cat_block .cat_block_lesson_data {margin-left: 10px;} */
}

@media only screen and (max-width: 1199px) {
  /* .additional_trainingCenter_Sub .traingcenterlist_wrapper .cat_block .cat_block_lesson_data {margin-left: 0;} */
  .additional_trainingCenter_Sub
    .traingcenterlist_wrapper
    .traingcenterlist_innerwrapper {
    width: 100%;
  }
  .additional_trainingCenter_Sub
    .traingcenterlist_wrapper
    .cat_block
    .cat_block_lesson_data
    .lessoncontent
    > img {
    display: block;
    width: 10%;
  }
}

@media only screen and (max-width: 480px) {
  .additional_trainingCenter_Sub
    .traingcenterlist_wrapper
    .cat_block
    .cat_block_lesson_data
    .lessoncontent
    > p {
    margin-left: 14px;
  }
}

/* /------------responsive end-------/ */

/* =======================Training Center===================== */

.trainingCenter_wrpr {
  min-height: 100vh;
  background-color: #cbf3fd;
  /* overflow: hidden; */
}

.trainingCenter_tile {
  text-align: center;
  background: rgb(18, 50, 71);
  background: linear-gradient(
    355deg,
    rgba(18, 50, 71, 1) 0%,
    rgba(28, 18, 67, 1) 100%
  );
  padding: 15px;
}

.trainingCenter_tile h1 {
  font-size: 30px;
  font-weight: 700;
  font-family: "Poppins", sans-serif;
  color: #fff;
}

.trainingCenter_main {
  margin-left: 0px !important;
  width: 100% !important;
  justify-content: space-between;
  padding: 0px !important;
  margin-top: 0 !important;
}

.traingcenterdata_wrapper {
  /* padding: 15px; */
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-left: 0 !important;
  padding-top: 0px !important;
}

.traingcenterdata_wrapper app-traing-center {
  /* margin: 0.5%; */
  border-radius: 0px;
  box-shadow: -1px 1px 6px #0000008f;
  width: 100%;
  transition: transform 0.2s;
  background: rgb(28 25 127);
  background: linear-gradient(138deg, rgb(28 24 126) 0%, rgb(9 39 72) 100%);
  padding: 0;
  border: 1px solid #f5f5f5;
}

.traingcenterdata_wrapper app-traing-center .tncgCenter_dta {
  margin: 5px;
}

.traingcenterdata_wrapper .tncgCenter_dta {
  background-color: #ffffff;
  border-radius: 0px;
}

.traingcenterdata_wrapper
  .tncgCenter_dta
  .media_content_wrapper
  .media_content {
  overflow: hidden;
  height: auto;
  padding: 8px;
  background: rgb(83, 91, 94);
  background: linear-gradient(
    138deg,
    rgb(127 134 137) 0%,
    rgba(41, 52, 58, 1) 100%
  );
  width: auto;
  position: relative;
}

.traingcenterdata_wrapper .mediacontrol_wrapper {
  padding: 0 !important;
  margin: 0;
}

.traingcenterdata_wrapper .mediacontrol_icon_wrapper {
  background: #fff;
  padding: 2px;
}

.traingcenterdata_wrapper .mat-progress-bar-buffer {
  background-color: #16c7f9;
}

.traingcenterdata_wrapper .mat-progress-bar-fill::after {
  background-color: #fcd602;
}

.traingcenterdata_wrapper .tncgCenter_dta .media_content_wrapper img {
  display: block;
  width: 100%;
  object-fit: cover;
  object-position: center;
  height: 100%;
}

.mediacontrol_wrapper .mediacontrol_icon_wrapper span {
  display: flex;
  /* align-items: center; */
}

.tittle_wrapper,
.description_wrapper,
.mad_button_wrapper {
  padding: 15px;
  /* width: 98%; */
}

.traingcenterdata_wrapper .mad_button_wrapper {
  display: flex;
  justify-content: flex-end;
}

/* .description_wrapper p{
  word-break: break-all;
} */

.tittle_wrapper {
  background: rgb(5, 41, 88);
  background: linear-gradient(
    180deg,
    rgb(5 41 88 / 81%) 0%,
    rgba(5, 41, 88, 1) 100%
  );
  display: flex;
  align-items: center;
}

.expan_icon_wrapper {
  color: #fff;
  display: flex;
}

.tittle_wrapper p {
  font-size: 25px;
  line-height: 28px;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  text-transform: capitalize;
  /* background: rgb(253,253,253);
  background: linear-gradient(180deg, rgba(253,253,253,1) 0%, rgba(194,195,196,1) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;  */
  margin: 0;
  padding: 5px;
  color: #fff;
  text-shadow: 0 0 2px #000;
}

.incomplete .tittle_wrapper {
  background: rgb(0, 197, 255);
  background: linear-gradient(
    180deg,
    rgba(0, 197, 255, 1) 0%,
    rgba(10, 133, 170, 1) 100%
  );
}

.incomplete .tittle_wrapper p {
  color: #fff;
  text-shadow: 1px 1px 5px #888;
}

.complete .tittle_wrapper {
  background: rgb(68, 209, 61);
  background: linear-gradient(
    180deg,
    rgba(68, 209, 61, 1) 0%,
    rgba(25, 148, 52, 1) 100%
  );
}

.complete .tittle_wrapper p {
  color: #fff;
  text-shadow: 1px 1px 5px #888;
}

.ongoing .tittle_wrapper {
  background: rgb(236, 224, 80);
  background: linear-gradient(
    180deg,
    rgba(236, 224, 80, 1) 0%,
    rgba(201, 195, 56, 1) 100%
  );
}

.ongoing .tittle_wrapper p {
  color: #fff;
  text-shadow: 1px 1px 5px #888;
}

.expan_icon_wrapper .mat-icon {
  background-repeat: no-repeat;
  display: inline-block;
  fill: currentColor;
  height: auto;
  width: auto;
  background: #fff;
  color: #000;
  border-radius: 100%;
  padding: 2px 1px 0 1px;
  margin-left: 10px;
}

.description_wrapper p {
  font-size: 18px;
  line-height: 22px;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  text-transform: capitalize;
}

.mad_button_wrapper button {
  background: linear-gradient(
    180deg,
    rgba(0, 200, 245, 1) 15%,
    rgba(23, 153, 176, 1) 81%
  );
  padding: 10px 25px;
  border-radius: 5px;
  font-size: 18px;
  color: #fff;
  font-weight: 600;
  border: none;
  margin: 2px;
  cursor: pointer;
}

.media_content video {
  object-fit: cover;
  height: 100%;
  width: 100%;
}

.media_content audio {
  width: 100%;
  position: relative;
  top: 50%;
}

.traingcenterList_wrapper {
  display: none;
}

.traingcenterList_wrapper {
  position: sticky;
  top: 0;
  height: fit-content;
  right: 0;
  /* border: 5px solid #00c8f5; */
  padding-left: 20px !important;
  padding-top: 0px !important;
}

.traingcenterList_wrapper app-traing-center,
.trainingCenter_main .tittle_wrapper {
  cursor: pointer;
}

.trainingCenter_wrpr .show_percentage {
  background-color: #16c7f9;
  margin: 0px 0 0px;
  padding: 26px 0px;
}

.trainingCenter_wrpr .show_percentage .trainingCenter_Progressbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ffffff;
  border-radius: 5px;
  box-shadow: 0px 1px 6px 2px rgb(0 0 0 / 16%);
  padding: 0px 29px;
}

.trainingCenter_wrpr
  .show_percentage
  .trainingCenter_Progressbar
  .totalcountshow {
  width: 35%;
}

.trainingCenter_wrpr
  .show_percentage
  .trainingCenter_Progressbar
  .progressbar_wrapper {
  width: 60%;
  display: flex;
  justify-content: end;
  align-items: center;
  background: rgb(255, 255, 255);
  /* background: linear-gradient(180deg, rgb(5 41 88 / 81%) 0%, rgba(5,41,88,1) 100%); */
  border-radius: 50px;
  padding: 20px 0;
}

.totalcountshow p {
  font-size: 25px;
  line-height: 25px;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  /* text-align: center; */
  color: #000000;
  padding: 5px 0;
}

.progressbar_wrapper mat-progress-bar {
  width: 94%;
  height: 20px;
  border-radius: 20px;
}

.progressbar_wrapper .mat-progress-bar .mat-progress-bar-buffer {
  background: #16c7f9;
  /* background: linear-gradient(90deg, rgba(214,123,233,1) 0%, rgba(214,123,233,1) 100%); */
  /* border: 1px solid #fff; */
}

.progressbar_wrapper .mat-progress-bar-fill::after {
  background-color: #fcd602 !important;
}

.progressbar_wrapper p {
  font-size: 22px;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  word-break: normal;
  white-space: nowrap;
  padding-left: 10px;
}

.colourTheme_wrpr {
  background-color: #000;
}

.colourTheme_Subwrpr {
  justify-content: space-between;
}

.colourTab {
  background-color: #fff;
  margin: 0 !important;
  padding: 0;
  max-width: 32.33% !important;
  flex-basis: 32.33% !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px !important;
  border-radius: 5px;
}

.colourTab .colourLogo {
  width: 16% !important;
}

.colourTheme_text {
  /* padding-left: 77px; */
  width: 80%;
  text-transform: uppercase;
  display: flex;
  align-items: center;
}

.colour_boolets {
  margin-right: 14px;
}

.colour_boolets img {
  display: block;
  width: 100%;
}

.colourTheme_text p {
  width: 80%;
  font-size: 24px;
  font-weight: 600;
  position: relative;
}

/* .colourTheme_text:nth-child(1)::after{
  content: "";
  position: absolute;
  background-color: #42ff00;
  height: 47px;
  width: 47px;
  border-radius: 50%;
  left: 0;
    top: -12%;
    transform: translateX(12%);
} */

.trainingCenter_wrpr .trainingCenter_Banner img,
.colourTheme_wrpr img {
  display: block;
  width: 100%;
}

.commonWidth_admin {
  width: 1764px;
  margin: 0 auto;
}

.trainingCenter_Banner {
  background: url(https://all-frontend-assets.s3.amazonaws.com/wound-care-for-home_Assets/W4H_training_bg.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: left center;
}

.trainingCenter_BannerSub {
  background: url(https://all-frontend-assets.s3.amazonaws.com/wound-care-for-home_Assets/W4H_trnbanner_hmn.png);
  background-repeat: no-repeat;
  background-size: auto 100%;
  background-position: right bottom;
}

.bannrContntWrpr {
  overflow: hidden;
}

.bannerContent {
  background: url(https://all-frontend-assets.s3.amazonaws.com/wound-care-for-home_Assets/W4H_bnrtxt_bg.png);
  background-repeat: no-repeat;
  background-size: cover;
  width: 42%;
  border-radius: 3px;
  border: 1px solid #27ddf5;
  padding: 30px 40px 40px;
  border-right: 0;
  margin: 70px 0;
  /* background-position: right center; */
}

.bannerContent h1 {
  font-size: 37px;
  font-weight: 600;
  color: #fff;
  /* padding: 30px 40px 40px; */
  line-height: 45px;
}

.bannerContent h1 span {
  color: #fdf402;
}

.trainingCenter_Sub {
  margin-left: 0px !important;
  width: 100% !important;
  justify-content: space-between;
  /* padding: 20px !important; */
  display: flex;
  margin: 20px 0 !important;
}

/* .trainingCenter_Sub .MuiGrid-root {
  flex: 1 0 66%;
} */

/* .traingcenterdata_wrapper {
  width: 60% !important;
}

.traingcenterList_wrapper {
  width: 38% !important;
} */

.alertPara {
  margin-bottom: 15px;
  font-size: 35px;
  color: white;
  padding: 0px 5px;
  background: #003166;
}

.trainingCenter_Sub .MuiGrid-grid-xl-8 {
  width: 70% !important;
  max-width: inherit !important;
  flex-basis: inherit !important;
}

.trainingCenter_Sub .MuiGrid-grid-xl-4 {
  width: 29% !important;
  max-width: inherit !important;
  position: relative;
  flex-basis: inherit !important;
}

.colourTab:first-child {
  margin-left: 0 !important;
}

/* .additional_trainingCenter_Sub .traingcenterdata_wrapper, .additional_trainingCenter_Sub .traingcenterlist_wrapper {background-color: #000;padding: 20% 0;} */

.viewModChips {
  width: inherit;
  text-align: center;
  max-width: 30%;
  margin: 4px;
}

.viewModChips:nth-child(even) {
  background-color: #0569ad;
  color: white;
}

.viewModChips:nth-child(odd) {
  background-color: #6b6c6c;
  color: white;
}

/* 
.colourTab:last-child{
  margin-right: 0 !important;
} */

@media only screen and (max-width: 1800px) {
  .trainingCenter_wrpr .show_percentage {
    padding: 26px 0%;
  }
  .trainingCenter_wrpr .show_percentage.commonWidth_admin {
    width: 100% !important;
  }
}

@media only screen and (max-width: 1640px) {
}

@media only screen and (max-width: 1464px) {
  .commonWidth_admin {
    width: 94%;
    /* margin: 0 auto; */
  }
  .trainingCenter_BannerSub {
    background-position: right -110px bottom;
  }
}

@media only screen and (max-width: 1199px) {
  .notes_details .MuiDialog-paper {
    max-width: 90% !important;
    min-width: 90% !important;
    margin: 0 !important;
  }
  .tittle_wrapper p {
    font-size: 22px;
    line-height: 24px;
  }
  .trainingCenter_Sub {
    display: flex;
    flex-direction: column-reverse;
  }
  .training_right_block .category_block {
    padding: 12px;
  }
  .traingcenterdata_wrapper {
    width: auto !important;
  }
  .traingcenterdata_wrapper app-traing-center {
    display: block;
  }
  .commonWidth_admin .MuiGrid-root {
    display: block !important;
  }
  .trainingCenter_wrpr .show_percentage .trainingCenter_Progressbar {
    display: block;
  }
  .trainingCenter_wrpr
    .show_percentage
    .trainingCenter_Progressbar
    .totalcountshow {
    width: auto;
    padding: 10px 0 0 0;
  }
  .trainingCenter_wrpr
    .show_percentage
    .trainingCenter_Progressbar
    .progressbar_wrapper {
    width: auto;
    padding: 5px 0 15px 0;
  }
  .trainingCenter_wrpr .show_percentage .trainingCenter_Progressbar {
    padding: 0 15px;
  }
  .commonWidth_admin .MuiGrid-root {
    display: flex !important;
    width: 100% !important;
  }
  .colourTab {
    width: auto;
    margin: 0 !important;
    max-width: 100% !important;
    flex-basis: inherit !important;
  }
  .colourTheme_wrpr {
    background-color: #000;
    overflow: hidden;
  }
  .commonWidth_admin .traingcenterList_wrapper {
    width: auto !important;
    padding-left: 0 !important;
    display: block !important;
  }
  .trainingCenter_BannerSub {
    background: none;
  }
  .bannerContent {
    width: auto;
    padding: 20px;
  }
  .bannerContent h1 {
    font-size: 35px;
  }
  .trainingCenter_BannerSub {
    background: none;
  }
  .bannerContent {
    width: auto;
    padding: 20px;
    margin: 30px 0;
  }
  .bannerContent h1 {
    font-size: 35px;
  }
  .PatientSearch .formGroup {
    flex: 1 0 48% !important;
  }
}

.commonWidth_admin .traingcenterList_wrapper {
  width: auto !important;
  padding-left: 0 !important;
  /* display: block!important; */
}
.center {
  text-align: center;
}

/* .trainingCenter_Banner{
  min-height: 250px;
} */

@media only screen and (max-width: 900px) {
  .bannerContent h1 {
    font-size: 30px;
    line-height: 35px;
  }
  .totalcountshow p,
  .progressbar_wrapper p {
    font-size: 20px;
    text-align: center;
  }
  .folder_view_con p {
    display: block;
  }
  .folder_view_con p strong {
    display: block;
    width: auto;
  }
  .folder_view_con p span {
    width: auto;
  }
}

@media only screen and (max-width: 560px) {
  .trainingCenter_wrpr
    .show_percentage
    .trainingCenter_Progressbar
    .progressbar_wrapper {
    display: block;
  }
  .progressbar_wrapper mat-progress-bar {
    width: auto;
  }
  .progressbar_wrapper p {
    text-align: center;
    padding: 5px 0 0 0;
  }
  .bannerContent h1 {
    font-size: 27px;
    line-height: 30px;
  }
  .bannerContent {
    margin: 20px 0;
  }
}

@media only screen and (max-width: 480px) {
  .commonWidth_admin .MuiGrid-root {
    display: block !important;
  }
  .colourTab .colourLogo {
    width: 16% !important;
    display: block;
    margin: 0 auto;
  }
  .colourTheme_text {
    width: auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .colourTheme_text p {
    width: auto;
  }
  .colourTheme_text p {
    font-size: 20px;
  }
  .colour_boolets {
    margin-right: 14px;
    width: 30px;
  }
  .bannerContent h1 {
    font-size: 21px;
    line-height: 24px;
  }
  .totalcountshow p {
    line-height: 24px;
  }
  .traingcenterdata_wrapper app-traing-center {
    padding: 0;
  }
}

/* =======================Training Center===================== */

/* =======================Contract list page templete modal css===================== */
.contractDetails .property_container {
  display: block !important;
}

/* ----- Saumya ------- */


.listflexblocknew li {
  display: flex;
  justify-content: space-between;
  text-align: left;
  padding: 10px;
  word-break: break-word;
  font-size: 16px;
  line-height: 20px;
  align-items: center;
}

.listflexblocknew li strong {
  width: 25%;
}

.listflexblocknew li span {
  display: block;
  width: 65%;
}

.listflexblocknew li:nth-child(odd) {
  background: #d7d7d74f;
}

.userList_modal .BoxMui_modal {
  width: 600px;
  max-width: 94%;
}

.userList_modal .BoxMui_modal .listflexblocknew {
  height: auto;
  /* overflow-y: scroll; */
}

.listflexblocknew .heading{
  text-align: left !important;
  background: #0569ad;
  color: white;
  font-size: 18px;
}


.headingblock-FV .heading{
 margin-bottom: 0px;
 box-shadow: none;
 padding: 0px;
}

.headingblock-FV{
background-color: #f2cf66;
color: #333;
margin-bottom: 10px;
padding: 15px;
font-size: 22px;
box-shadow: 0 5px 20px rgb(0 0 0 / 5%);
border-radius: 5px;
display: flex;
align-items: center;
flex-wrap: wrap;
font-weight: 600;
justify-content: space-between;
}

.uName {
  font-size: 15px;
}

/* .folder_view_left .folder_btn{
  line-height: 18px!important;
} */

.folder_btn .count {
  width: 59px;
  height: auto;
  color: black;
  text-decoration: none;
  background: #f2cf66;
  border-radius: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -8px;
  right: 0px;
  font-size: 14px;
  background: #f2cf66;
  font-weight: bold;
  text-decoration: none;
  text-shadow: none;
  border: 1px solid black;

}

.view_contract_modal {
  margin: 25px !important;
  padding: 0 !important;
}

.view_contract_modal .cont-body {
  padding: 25px !important;
}

.view_contract_modal .cont-body p {
  font-size: 18px !important;
  line-height: 33px !important;
  text-align: justify;
}

.view_contract_modal .cont-body h4 {
  font-size: 30px !important;
}

.view_contract_modal .btn-grp .signatureBlock {
  width: 100%;
  border: 4px solid #0569ad;
}

.loaderBlock .loader {
  margin: 15px auto !important;
  width: 50%;
}

.signatureBlock .sign {
  width: 100%;
  background-color: #ffff;
}

.signatureBlock .sign .MuiInputLabel-formControl {
  background-color: transparent;
  color: #242222;
  padding: 2px;
  width: 105px;
  text-align: center;
}

.signatureBlock {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 10px;
}

.signMod {
  width: 100%;
  border: 1px dashed #000;
  margin: 10px 0;
}

.signMod .signModHere {
  background-color: #ffff;
  padding: 9px;
  min-height: 54px;
  max-height: 54px;
  font-family: Parisienne, cursive;
  font-size: 25px;
}

.view_contract_modal .btn-grp {
  background: #0569ad;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 18px 0;
}

.view_contract_modal .btn-grp .sendBtn {
  width: 15%;
  padding: 15px;
  border: 2px solid white;
}

.view_contract_modal .btn-grp .DLBtn {
  width: 5%;
  padding: 15px;
  border: 2px solid white;
}

.UA-scroll-activity {
  max-height: 800px;
  overflow: hidden;
  overflow-y: auto;
}

.contract_modal .BoxMui_modal {
  width: 1024px;
  height: auto;
  max-width: 100%;
}

.contract_modal .BoxMui_modal {
  padding: 0px !important;
}

.contract_modal textarea {
}
/* .contract_modal .heading{
  position: sticky;
  top: 0;
} */

.contract_modal .btn-grp {
  background: #0569ad;
}

.contract_modal .btn-grp .notesblock {
  width: 100%;
  border: 4px solid #0569ad;
}

.contract_modal .cont-body {
  max-height: 61vh;
  overflow: hidden;
  overflow-y: auto;
}

.contract_modal .btn-grp .notesblock .notesinput {
  width: 100%;
  height: 67px !important;
  background: white;
  overflow-y: scroll;
  /* resize: vertical; */
  padding: 8px;
  /* border: 2px solid #0569ad00; */
}

.contractdrawer .MuiDrawer-paper {
  width: 53%;
  margin: 0 auto;
}

.contractdrawer .heading {
  text-align: center;
  position: sticky;
  top: 0;
}

.contractdrawer .btn-grp {
  margin: 10px auto;
  padding: 10px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.contractdrawer .btn-grp .conts {
  padding: 17px;
  margin: 2px;
}

.contractdrawer .drawerCloseIcon {
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  color: white;
}

.personalInfoFV span.chipss {
  margin: 0 3px;
}

.user-activity {
  margin: 20px;
  border-radius: 10px;
}

.user-activity .ua-data-block {
  list-style: none;
}

.user-activity .ua-data-block .actionBtn {
  text-decoration: none !important;
}

.user-activity:nth-child(2n - 1) {
  background-color: #7ecbfff0;
  padding: 10px;
  border: 3px solid #3498db;
}

.user-activity .heading {
  display: flex;
  text-transform: capitalize;
}

/* .user-activity:nth-child(2n - 1) .heading{
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 5px;
    background: #0093f7;
    color: white;
    font-weight: 500;
    font-size: 17px;
}

.user-activity:nth-child(2n) .heading{
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 5px;
    background: #f2cf67;
    color: black;
    font-weight: 500;
    font-size: 17px;

} */

.user-activity:nth-child(2n) {
  background-color: rgb(255 237 182);
  padding: 10px;
  border: 3px solid #f2cf66;
}

.adminlisttable_v2 tbody tr td span.training-green,
.adminlisttable_v2 tbody tr td span.training-red {
  border-radius: 25px;
  color: #fff;
  display: block;
  font-weight: 700;
  padding: 5px;
  text-align: center;
  width: 80px;
}

.traing-percentage-number-color {
  border-radius: 25px;
  color: #fff;
  display: block;
  font-weight: 700;
  padding: 5px;
  text-align: center;
  width: 80px;
}

.adminlisttable_v2 tbody tr td span.training-red {
  background: #d70101;
}

.adminlisttable_v2 tbody tr td span.training-yellow {
  background: #e3ab10;
}

.adminlisttable_v2 tbody tr td span.training-green {
  background: #a0a945;
}

.configRoundRobinModal .feildContainer {
  display: flex;
  flex-direction: row;
}

.ReportIssueModal .feildContainer {
  display: flex;
  flex-direction: column;
}

.ReportIssueModal .feildContainer .textF {
  padding: 8px;
}

.training-block-per-user .TS-block {
  background: #e1e1e1;
  margin: 20px 0;
}

.training-block-per-user .TS-block {
  background: #e1e1e1;
  margin: 20px 0;
}

.training-block-per-user .TS-block .progressBarNew {
  width: 48%;
}

.training-block-per-user .TS-block .progressBarNew span {
  width: 100%;
}

.Fv-tags {
  list-style: none;
  margin: 13px 0;
  position: relative;
}
.Fv-tags li {
  position: relative;
  margin: 10px 0;
}

.training-block-per-user .total-percentage {
  padding: 10px;
  /* position: sticky; */
  top: 0px;
  background: white;
}

.qIcon {
  cursor: pointer;
  font-size: 19px !important;
  background: #e7c14f;
  color: black;
  padding: 2px;
  border-radius: 25px;
  position: relative;
  left: -26px;
  top: -11px;
}

.stageChipB {
  position: relative;
}

.folder_view_con .stageChipB::after{ content: '';
  width: 20px;
  height: 12px;
  background: #7a7259;
  position: absolute;
  right: 0;
  top: 50%;     transform: translateY(-50%);  clip-path: polygon(40% 0%, 40% 20%, 100% 20%, 100% 80%, 40% 80%, 40% 100%, 0% 50%);}

  .folder_view_con .stageChipB:last-of-type:after{ display: none;}

.tagsBlck {
  position: relative;
}

.chipDesBuble {
  display: none;
  transform: translatey(0px);
  animation: float 5s ease-in-out infinite;
  /* mix-blend-mode: multiply; */
  text-align: left;
  text-transform: capitalize;
  font-weight: 500;
  /* letter-spacing: 3px; */
  /* font-size: 15px; */
  color: #000000;
  background-color: #f2cf66;
  border: 3px solid #d1b358;
  padding: 15px;
  border-radius: 11px;
  position: absolute;
  top: -190px;
  margin: 10px;
  /* box-shadow: 20px 20px #83af9b; */
  font-family: "Baloo 2", cursive;
  /* border: 1px solid #c8c8a9; */
  z-index: 999;
  width: 200px;
  font-size: 16px;
}
.forLife_Cycle .chipDesBuble{
  top: -80px;
}
.forLife_Cycle .tagChipwrp:nth-child(1) .chipDesBuble{
  top: 0; 
  animation:none;
  padding: 5px 15px;
}
.forLife_Cycle .tagChipwrp:last-child .chipDesBuble{
  top: -90px;
  animation:none;
  padding: 5px 15px;

}
.chipDesBuble:after {
  transform: translatey(0px);
  animation: float2 5s ease-in-out infinite;
  content: ".";
  font-weight: bold;
  -webkit-text-stroke: 0.5px #e7c14f;
  -webkit-text-fill-color: #e7c14f;
  /* // border: 1px solid #c8c8a9; */
  text-shadow: 22px 22px #e7c14f;
  text-align: left;
  font-size: 55px;
  width: 55px;
  height: 11px;
  line-height: 30px;
  border-radius: 11px;
  background-color: #3498db;
  position: relative;
  display: block;
  bottom: -30px;
  left: 0;
  box-shadow: 22px 22px #e7c14f;
  z-index: -2;
}
.forLife_Cycle .tagChipwrp:nth-child(1) .chipDesBuble:after, .forLife_Cycle .tagChipwrp:last-child .chipDesBuble::after{
  display: none;
}
.qIcon:hover + .chipDesBuble {
  display: block;
}

@keyframes float {
  0% {
    transform: translatey(0px);
  }
  50% {
    transform: translatey(-20px);
  }
  100% {
    transform: translatey(0px);
  }
}

@keyframes float2 {
  0% {
    line-height: 30px;
    transform: translatey(0px);
  }
  55% {
    transform: translatey(-20px);
  }
  60% {
    line-height: 10px;
  }
  100% {
    line-height: 30px;
    transform: translatey(0px);
  }
}

/* --------------------- */

/* /-----------------modal css-------/ */

/* .MuiDialogActions-root {
    justify-content: center !important;
    padding-bottom: 15px !important;
}

.MuiDialog-paper {
    border: 10px solid #0569ad;
}

.MuiDialogTitle-root {
    padding: 20px 80px !important;
}

.MuiDialogActions-root button {
    border-radius: 5px;
    box-shadow: 2px 3px 8px #225a918f!important;
    color: #fff!important;
    font-size: 20px;
    line-height: 26px;
    margin-right: 8px;
    padding: 8px 20px;
    text-transform: capitalize;
    background: var(--blue2);
}

.contractDetails .MuiDialog-paper p {
    color: #003166;
    font-size: 24px;
    font-weight: 500;
    margin-bottom: 20px;
    padding: 0;
    text-align: center;
    line-height: 30px;
}

.MuiDialogActions-root button:hover {
    background-color: var(--deep-blue);
}

.notes_main_header {
    padding: 0 20px !important;
    margin-top: 20px !important;
}

.notes_main_header p {
    margin-bottom: 0;
    font-size: 35px;
    color: var(--white);
    padding: 10px 5px;
    background: #003166;
    text-align: center;
}
.notes_details .notes_header p {text-align: center;}

.view_details .MuiDialogActions-root button {
    display: none;
}

.view_details .MuiDialogActions-root {
    padding: 0 !important;
} */
.delete_modal .MuiPaper-root {
  width: 600px !important;
}
.delete_modal .MuiTypography-h6 {
  padding: 0 !important;
}
.delete_modal .action_btn_container {
  display: flex;
}
.delete_modal .notes_header p {
  margin-top: 10px;
}
.muiTable .MuiToolbar-root .search_btn {
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  font-size: 18px;
}
.muiTable .MuiToolbar-root .search_btn::after {
  content: "\f002";
}

.muiTable .MuiToolbar-root .search_btn svg {
  display: none;
}

/* ============================Training-Center CSS======================== */
.trngCentr_banner_mainwrapper {
  background: url(https://all-frontend-assets.s3.amazonaws.com/aspire-pages-banner/ALF_trangcntr_bnrbg.webp);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.trngCentr_banner_mainwrapper .trngCentr_banner_subwrapper {
  background: url(https://all-frontend-assets.s3.amazonaws.com/aspire-pages-banner/tngCntr_bnr_hmn1.webp);
  background-repeat: no-repeat;
  background-size: auto 100%;
  background-position: right bottom;
  padding: 62px 0;
  position: relative;
}
.trngCentr_banner_mainwrapper .trngCentr_banner_subwrapper::before {
  content: "";
  position: absolute;
  background: url(https://all-frontend-assets.s3.amazonaws.com/aspire-pages-banner/bnr_hmn_bg.png);
  background-repeat: no-repeat;
  height: 100%;
  width: 121px;
  top: 0px;
  right: -18px;
}
.trngCentr_banner_mainwrapper .trngCentr_banner_subwrapper::after {
  content: "";
  position: absolute;
  background: url(https://all-frontend-assets.s3.amazonaws.com/aspire-pages-banner/txt_bnr_side3.png);
  background-repeat: no-repeat;
  height: 100%;
  width: 121px;
  right: 36%;
  top: 0;
  transform: translateX(-9%);
}
.trngCentr_banner_blockwrap {
  background: url(https://all-frontend-assets.s3.amazonaws.com/aspire-pages-banner/text_bnr_Mbg.png);
  background-repeat: no-repeat;
  background-size: cover;
  width: 62%;
  border-radius: 20px 0 0 20px;
  position: relative;
}
.trngCentr_block_subwrap {
  /* background: url(https://all-frontend-assets.s3.amazonaws.com/aspire-pages-banner/alf_subbnr.png); */
  background: url(https://all-frontend-assets.s3.amazonaws.com/aspire-pages-banner/ALF_new_BGtrng.png);
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 20px 0 0 20px;
  width: 91%;
  position: relative;
}
.trngCentr_block_subwrap::after {
  content: "";
  position: absolute;
  /* clip-path: polygon(100% 0, 100% 23%, 0 100%, 0 77%); */
  /* background-color: #3befa1;  */
  height: 100%;
  width: 123px;
  right: -27px;
  background: url(https://all-frontend-assets.s3.amazonaws.com/aspire-pages-banner/ALF_inner_sidecolor.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  top: 0;
}
.paralgrm1 {
  position: absolute;
  bottom: -62px;
  left: -8px;
  z-index: 9;
}
.paralgrm2 {
  position: absolute;
  left: -4px;
  top: 50%;
  transform: translateY(-8%);
  z-index: 9;
}
.paralgrm3 {
  position: absolute;
  right: -2%;
  top: 27%;
  transform: translate(-7%, -21%);
  z-index: 9;
}
.paralgrm4 {
  position: absolute;
  top: -11px;
  right: 9%;
  z-index: 9;
}
.paralgrm5 {
  position: absolute;
  bottom: -62px;
  right: 24%;
  z-index: 9;
}
.trngCentr_banner_mainwrapper .trngCentr_block_text {
  padding: 38px 45px;
  /* background: url(https://all-frontend-assets.s3.amazonaws.com/aspire-pages-banner/txt_bnrM.png); */
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 20px 0 0 20px;
  /* clip-path: polygon(6% 0%, 92% 0, 81% 100%, 0 101%, 0 55%); */
  /* background-color: #fff; */
}
.trngCentr_banner_mainwrapper .trngCentr_block_text h3 {
  font-weight: var(--main-font-SemiBold);
  font-size: 39px;
  line-height: 47px;
  color: #000;
  width: 91%;
}
.trainingCenter_main .commonWidth_training {
  width: 1764px;
  margin: 0 auto;
}

.modal_titlenew{ margin-bottom: 15px;}
@media only screen and (max-width: 1900px) {
  .trainingCenter_main .commonWidth_training {
    width: 96%;
  }
}
@media only screen and (max-width: 1840px) {
  .trngCentr_banner_mainwrapper .trngCentr_banner_subwrapper {
    background-position: right -60px bottom;
  }
}
@media only screen and (max-width: 1780px) {
  .trngCentr_banner_mainwrapper .trngCentr_block_text h3 {
    font-size: 30px;
    line-height: 39px;
  }
  /* .trngCentr_banner_mainwrapper .trngCentr_block_text{
        clip-path: polygon(6% 0%, 92% 0, 81% 126%, 0 101%, 0 55%);
    } */
  .trngCentr_block_subwrap {
    width: 86%;
  }
  .trngCentr_block_subwrap::after {
    width: 108px;
    right: -23px;
  }
}
@media only screen and (max-width: 1620px) {
  .trngCentr_banner_blockwrap {
    background-position: right 46px bottom 0;
    width: 61%;
  }
  .trngCentr_banner_mainwrapper .trngCentr_block_text h3 {
    font-size: 27px;
    line-height: 38px;
  }
  .trngCentr_block_subwrap {
    width: 94%;
  }
  .trngCentr_block_subwrap::after {
    width: 88px;
    right: -11px;
  }
}
@media only screen and (max-width: 1600px) {
  .trngCentr_banner_mainwrapper .trngCentr_banner_subwrapper {
    background-position: right -135px bottom;
  }
  .trngCentr_banner_mainwrapper .trngCentr_banner_subwrapper::after {
    right: 30%;
    transform: translateX(0%);
  }
  .trngCentr_banner_mainwrapper .trngCentr_banner_subwrapper {
    background-position: right -167px bottom;
  }
  .trngCentr_banner_mainwrapper .trngCentr_banner_subwrapper::before {
    right: -53px;
  }
}
@media only screen and (max-width: 1550px) {
  .trngCentr_block_subwrap::after {
    width: 104px;
    right: -11px;
  }
}
@media only screen and (max-width: 1470px) {
  .trngCentr_block_subwrap {
    width: 97%;
    background-position: right 61px top;
  }
  .trngCentr_banner_blockwrap {
    background-position: right 0px bottom 0;
  }
  .trngCentr_banner_mainwrapper .trngCentr_block_text h3 {
    width: 74%;
  }
  .paralgrm3 {
    right: 3%;
    transform: translate(-58%, -21%);
  }
  .paralgrm4 {
    right: 22%;
  }
  .paralgrm5 {
    right: 37%;
  }
  .trngCentr_block_subwrap::after {
    right: 46px;
    width: 91px;
  }
  .trngCentr_banner_mainwrapper .trngCentr_block_text h3 {
    font-size: 22px;
    line-height: 30px;
  }
}
@media only screen and (max-width: 1280px) {
  .trngCentr_banner_mainwrapper .trngCentr_block_text h3 {
    font-size: 20px;
    line-height: 28px;
  }
  .trngCentr_block_subwrap::after {
    right: 51px;
    width: 87px;
  }
}
@media only screen and (max-width: 1100px) {
  .trngCentr_banner_mainwrapper .trngCentr_banner_subwrapper {
    background: none;
  }
  .trngCentr_banner_blockwrap {
    width: 100%;
  }
  .trngCentr_block_subwrap {
    width: 100%;
    background-position: right 0px top;
  }
  .trngCentr_banner_mainwrapper .trngCentr_block_text {
    padding: 42px 54px;
  }
  .trngCentr_banner_mainwrapper .trngCentr_block_text h3 {
    font-size: 27px;
    line-height: 35px;
    width: 94%;
  }
  .paralgrm3,
  .trngCentr_block_subwrap::after {
    display: none;
  }
}
@media only screen and (max-width: 900px) {
  .paralgrm1,
  .paralgrm2,
  .paralgrm4,
  .paralgrm5,
  .trngCentr_banner_mainwrapper .trngCentr_banner_subwrapper::after,
  .trngCentr_banner_mainwrapper .trngCentr_banner_subwrapper::before {
    display: none;
  }
  .trngCentr_block_subwrap {
    background-size: 100% 100%;
  }
  .trngCentr_banner_blockwrap {
    background-size: 100% auto;
  }
  .trngCentr_banner_mainwrapper .trngCentr_banner_subwrapper {
    padding: 30px 0;
  }
  .trngCentr_banner_mainwrapper .trngCentr_block_text {
    padding: 30px 34px;
  }
}
@media only screen and (max-width: 600px) {
  .trngCentr_banner_mainwrapper .trngCentr_block_text h3 {
    font-size: 20px;
    line-height: 28px;
  }
}
@media only screen and (max-width: 450px) {
  .trngCentr_block_subwrap {
    background: none;
    background-color: #fff;
    border-radius: 10px;
  }
  .trngCentr_banner_mainwrapper .trngCentr_block_text {
    padding: 15px;
  }
  .trngCentr_banner_mainwrapper .trngCentr_block_text h3 {
    width: 100%;
  }
}

.forprviewform .submitbtnsection {
  display: none;
}
.form_preview {
  padding-top: 15px;
  padding-bottom: 15px;
}

.forprviewform {
  text-align: start !important;
  display: block !important;
}
.forprviewform .css-1nrlq1o-MuiFormControl-root {
  margin: 15px 0;
}

/* .forprviewform .previewfield input {
  padding: 10px ;
} */
.previewfield .css-dmmspl-MuiFormGroup-root {
  display: block !important;
}

.previewfield_modal {
  /* padding: 40px !important; */
  width: 600px;
}
.previewfield_modal .datamodalwrper {
  height: 200px;
  overflow-y: scroll;
}

.common-modalbox .mat-dialog-container {
  background: #f1fff1;
}

.quiz-container .heading {
  text-align: center;
  /* background-color: #3a24ad; */
  color: #fff;
  text-shadow: 1px 1px 5px #888;
  margin-bottom: 10px;

  background: linear-gradient(
    180deg,
    rgba(236, 224, 80, 1) 0%,
    rgba(201, 195, 56, 1) 100%
  );
}

.quiz-container .modal-btns {
  /* background-color: #3a24ad;
  color: #fff;
  margin-right: 10px; */

  color: #fff;
  border-radius: 5px;
  margin-right: 10px;
  border: none;
  font-size: 18px;
  font-weight: 600;
  padding: 1px 25px;
  background: linear-gradient(180deg, #00c8f5 15%, #1799b0 81%);
}

.quiz-container .btns-wrap {
  margin-top: 15px;
  text-align: center;
}

.quiz-container .question {
  font-weight: bold;
}

.quiz-container .option-wrap input {
  margin-right: 5px;
}

.quiz-container .mat-progress-bar {
  margin-top: 15px;
}
.groupview_modal {
  padding: 30px !important;
  overflow-y: scroll;
  height: 400px;
}

.folder_view_con div p span .chip_block {
  padding: 0;
  font-size: inherit;
  max-width: 100%;
}
/* /-------------css by subhashis-----------/ */

.outer_width_wrp {
  width: 820px !important;
  max-width: 94%;
}
.outer_flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #e6e6e6;
  margin: 10px 0;
  padding: 10px;
  border-radius: 5px;
}
.modal_inner_wrp {
  width: 70%;
}
.bold_text {
  font-weight: 600;
}
.right_details {
  margin-left: 20px;
}
.slug_container {
  font-size: 30px;
  color: #fff;
  background: #003166;
  text-align: center;
  font-weight: 600;
  padding: 10px;
}
.scroll_wrp {
  height: 400px;
  overflow-y: scroll;
}
@media only screen and (max-width: 499px) {
  .outer_flex {
    flex-direction: column;
  }
  .modal_inner_wrp {
    width: 100%;
  }
  .outer_flex button {
    margin-top: 10px;
  }

  .BoxMui_modalPage {
    width: 90%;
  }
}

/* -------------CIF Form------------- */
.adminformbody .form_contain_wraper h3 {
  width: 100%;
}

/* .adminformbody .form_contain_wraper .cif {
  display: flex;
  position: absolute;
  bottom: 0;
  left: 60%;
} */
.form_container .cif_contact_form {
  width: 65%;
  margin: 0 auto;
}

/* ---------------------------------- */

.lead_select_button {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.lead_select_button .MuiButton-containedPrimary {
  margin: 0 5px;
  font-size: 18px;
}
.lead_select_button .MuiButton-containedPrimary.active{
      background: #f67d24;
}

.download_csv {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-weight: bold;
}

.download_csv .download_text .download_text_star {
  color: red;
}

/* -----------------show-tag-list-------------------- */
.showTagListwrpr .tagChipwrp,
.showstageListwrpr .tagChipwrp {
  position: relative;
}
.showTagListwrpr .MuiChip-root,
.showstageListwrpr .MuiChip-root {
  margin: 10px;
  position: relative;
  margin-bottom: 50px;
  width: 250px;
}
.showTagListwrpr .tagChipwrp:nth-of-type(1) .MuiChip-root,
.showstageListwrpr .tagChipwrp:nth-of-type(1) .MuiChip-root {
  background-color: #7ecbfff0;
}
.showTaglistMap,
.showStagelistMap {
  display: flex;
  flex-direction: column;
}
.showTagListwrpr .tagChipwrp::before,
.showstageListwrpr .tagChipwrp::before {
  content: "";
  position: absolute;
  background: url(https://all-frontend-assets.s3.amazonaws.com/aspire-pages-banner/Dotted-line1.png);
  height: 50px;
  width: 5px;
  background-repeat: no-repeat;
  bottom: -5px;
  right: 50%;
  background-size: cover;
}
.showTagListwrpr .tagChipwrp:last-child::before,
.showstageListwrpr .tagChipwrp:last-child::before {
  display: none;
}
.showTagListwrpr .tagChipwrp:last-of-type .MuiChip-root,
.showstageListwrpr .tagChipwrp:last-of-type .MuiChip-root {
  margin-bottom: 0px;
}
.tagChipwrp .qIcon {
  position: absolute;
  right: 54px;
  top: 0;
  left: unset;
}
.largeModal .addedit_wrp .dataform1_wrapper::before,
.largeModal .addedit_wrp .dataform1_wrapper::after {
  display: none;
}
@media only screen and (max-width: 560px) {
  .tagChipwrp .qIcon {
    right: 10px;
  }
  .showTagListwrpr .MuiChip-root,
  .showstageListwrpr .MuiChip-root {
    /* max-width: 200px; */
    width: 90%;
    max-width: unset;
  }
}

.modalblock .BoxMui_modal h6 {
  font-size: 18px;
  margin-bottom: 10px;
}
.LeadFolderMain .folder_view_con .leadlistcon_wrapper {
  overflow: hidden;
}
.LeadFolderMain .folder_view_con .leadlistcon_wrapper .leadlistcon {
  word-wrap: break-word;
}

.sendconfirmmail {
  margin-left: auto;
}
.editiconinsucccess {
  cursor: pointer;
}

.modalview_group .nodatafound {
    text-align: center;
    font-weight: bold;
    font-size: 20px;
}


/*****************lead add edit form**************/

/* .lead_add_edit_wrapper{
  padding-bottom: 0;
}

.lead_add_edit_wrapper .Formsubmit_button{
  margin-bottom: 25px;
} */

/*----------------lifeCycle MOdal----------------------------------*/
.forLife_Cycle .showTagListwrpr, .forLife_Cycle .showstageListwrpr{
  max-height: 500px;
  overflow: hidden;
  overflow-y: auto;
}
.forLife_Cycle .BoxMui_modal{
  padding: 20px 0 !important;
}
/*****************css by subhashis**************/

.followup_wrap {height: 700px;overflow-y: scroll;}
.followup_container {width: 900px !important;max-width: 90%;}


@media only screen and (max-width: 560px) {
.followup_wrap .dataform2_wrapper {padding: 10px 0;}


}



